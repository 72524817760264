
import Vue from "vue";
import Button from "@/components/Dashboard/Inputs/Button/Button.vue";
import Register from "../Register/Register.vue";
import Login from "../Login/Login.vue";

export default Vue.extend({
  props: {
    closeModals: Function,
  },
  components: {
    Register,
    Login,
    Button,
  },
  data() {
    return {
      login: "Einloggen",
      register: "Anmeldung",
      border: "none",
      open: false,
      openLogin: false,
      openForgot: false,
      item: {
        route: "Timeline",
      },
    };
  },
  computed: {
    createLinearRadient(): string {
      return "linear-gradient(305deg, #717CBD, #7b78bc, #9c4cc3)";
    },
  },
  methods: {
    onClickOpen() {
      this.open = true;
      this.$emit("modal", this.open, !this.openLogin);
      this.openLogin = false;
    },
    onClickLogin() {
      this.openLogin = true;
      this.$emit("modal", this.openLogin);
      this.open = false;
    },
    closeRegisterAfterSubmit() {
      this.openLogin = false;
    },
    closeLoginModal() {
      this.openLogin = false;
      this.open = false;
    },
    openedForgot(e) {
      this.openForgot = true;
      this.openLogin = false;
      this.$emit("openForgot", this.openForgot);
    },
    closeRegisterModal() {
      this.open = false;
    },
  },
});
