
import Vue from "vue";
import IO from "socket.io-client";
let socket;
import { backendUrl, frontendUrl, socketUrl } from "@/helper/httpHelper";
import UserService from "@/Services/user.service";
import RightInfo from "./RightInfo.vue";
import ClientInfoNav from "./ClientInfoNav.vue";

export default Vue.extend({
  components: {
    ClientInfoNav,
    RightInfo,
  },
  data() {
    return {
      page: 2,
      loading: true,
      numberOfPages: 0,
      totalUsers: 0,
      options: {},
      headers: [
        { text: "Image", value: "image", width: "500px" },
        { text: "Client", value: "user.displayName", width: "300px" },
        { text: "Profile", value: "profile.displayName", width: "300px" },
        { text: "Image", value: "profileImage", width: "400px" },
      ],
      users: [],
      clearFields: false,
      backendUrl: backendUrl,
    };
  },
  watch: {
    options: {
      handler() {
        this.getChannels();
      },
      deep: true,
    },
  },
  methods: {
    getChannels() {
      const { page, itemsPerPage } = this.options;

      if (itemsPerPage == -1) {
        UserService.getAllChannels(this.$route.params.id)
          .then((res) => {
            console.log(res);
            this.users = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        UserService.getChannels(
          this.$route.params.id,
          page,
          itemsPerPage,
          "DESC"
        )
          .then((res) => {
            this.users = res.data.data;
            this.page = res.data.meta.page;
            this.totalUsers = res.data.meta.itemCount;
            this.numberOfPages = res.data.meta.pageCount;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  mounted() {
    this.getChannels();
  },
});
