import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/de.json';
import VueToasted from 'vue-toasted';
import 'v-infinite-scroll/dist/v-infinite-scroll.css';
import InfiniteScroll from 'v-infinite-scroll';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import SpinnerComponent from './components/Spinner/SpinnerComponent.vue';
import './assets/scss/main.scss';
import 'vue-select/src/scss/vue-select.scss';
// import SocketIO from "socket.io-client"
import InfiniteLoading from "vue-infinite-loading";


Vue.config.productionTip = false;
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('Spinner', SpinnerComponent);
Vue.component("infinite-loading", InfiniteLoading);

const parsedRules = rules as unknown as { [key: string]: any[] };
Object.keys(rules).forEach((rule: string) => {
  extend(rule, {
    ...parsedRules[rule],
    message: messages[rule],
  });
});

Vue.prototype.window = window;
// Vue.prototype.$soketio = SocketIO('http://localhost:3000');
Vue.use(InfiniteScroll);
Vue.use(VueToasted, {
  duration: 2000,
  theme: 'toasted-primary',
  className: ['toasting'],
  iconPack: 'mdi',
  singleton: true,
  position: "bottom-right"
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
