import { Http } from '../helper/httpHelper';

class MassSpamService {
  createMassSpam = (createMassSpam) => Http.post('/mass-spam', createMassSpam);

  getMassSpams = () => Http.get('/mass-spam');

  getMassSpamById = (id: number) => Http.get(`/mass-spam/${id}`);

  getMassSpamsUserCount = (createMassSpam) => Http.post('/mass-spam/user-count', createMassSpam);
}

export default new MassSpamService();
