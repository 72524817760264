import { Http } from '@/helper/httpHelper';
import { RegisterUser } from '@/types/registerUser';
import { createAgentDto } from '@/types/createAgentDto';
import { createProfile } from '@/types/createProfile';
import { Role } from "@/types/Role";

class UserService {
  registerUser = (registerUser: RegisterUser, referenceId?: string) => Http.post(`/auth/register/${referenceId || ''}`, registerUser);

  registerAgent = (createAgent: createAgentDto) => Http.post('/user', createAgent);

  getAllUsers = (query: string) => Http.get(`/user${query ? '?query=' + query : ''}`);
  getUsers = (query: string, page?: number, take?: number, order: string = 'DESC') => Http.get(`user?${query ? 'query=' + query + '&' : ''}page=${page}&take=${take}&order=${order}`);

  getUser = (id: number) => Http.get(`/user/${id}`);

  getAgentOrAdminByRole = (role: Role, page?: number, take?: number, order: string = 'DESC') => Http.get(`/user/agent/${role}?page=${page}&take=${take}&order=${order}`);
  getAllAgentOrAdminByRole = (role: Role) => Http.get(`/user/agent/${role}`);

  updateUser = (id: number, registerUser: any) => Http.put(`/user/${id}`, registerUser);

  deleteUser = (id: number) => Http.delete(`/user/${id}`);

  deleteAccount = () => Http.delete('/user/account');

  createProfile = (profile: createProfile) => Http.post('/profile', profile, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  getProfiles = (page?: number, take?: number, order: string = 'DESC') => Http.get(`/profile?page=${page}&take=${take}&order=${order}`);
  getAllProfiles = () => Http.get('/profile');

  getUsersWithoutDeleted = (nameSearch: string) => Http.get(`/user/without-deleted/${nameSearch}`);

  getProfilesWithoutDeleted = (nameSearch: string) => Http.get(`/profile/without-deleted/${nameSearch}`);

  addFriendRequest = (userId: number) => Http.post(`/user/friend/${userId}`);

  friendList = (userId: number) => Http.get(`/user/friend/${userId}`);

  getAdvertiserRefrences = (userId: number, page?: number, take?: number, order: string = "DESC", startDate?: string, endDate?: string) =>
    Http.get(`/user/references/${userId}${page != null ? `?page=${page}` : null}${take != null ? `&take=${take}` : null}&order=${order}${startDate != null ? `&firstDate=${startDate}` : ''}${endDate != null ? `&secondDate=${endDate}` : ''}`);

  getAdvertiserRefrencesFilterByDate = (userId: number, page?: number, take?: number, order: string = "DESC", startDate?: string, endDate?: string) =>
    Http.get(`/user/references/${userId}?order=${order}${startDate != null ? `&firstDate=${startDate}` : ''}${endDate != null ? `&secondDate=${endDate}` : ''}`);


  checkIfFriends = (id: number) => Http.get(`/user/friend/check/${id}`);

  getAllMessageCount = (chatterId: number) => Http.get(`/messages/chatter/${chatterId}`);

  getMessageCount = (chatterId: number, page: number, take: number, order: string = "DESC") => Http.get(`/messages/chatter/${chatterId}?order=${order}&page=${page}&take=${take}`);

  searchForProfiles = (query?: string, gender?: number, age?: number) => Http.get(`profile/search?query=${query ? query : ''}${gender !== null && gender !== undefined ? `&gender=${gender}` : ''}${age ? `&age=${age}` : ''}`);

  checkIfFriendRequestIsSent = (profileId: number) => Http.get(`/user/friend/check/${profileId}`);

  getFriendRequestFromUser = (userId: number, profileId: number) => Http.get(`/user/friend/${userId}/${profileId}/check`);

  approveFriendRequest = (requestId: number) => Http.post(`/user/friend/${requestId}/approve`);

  getAllAdvertiserRefrences = (userId: number) => Http.get(`/user/references/${userId}`);

  generateRandomProfiles = () => Http.get('/profile/random');

  searchClients = (query?: string) => Http.get(`/user/clients/search?query=${query ? query : ''}`);

  uploadProfilePicture = (userId: number, userProfile) => Http.post(`/profile/${userId}/upload`, userProfile);

  updateGallaryimageStatus = (id: number, isPrivate: boolean) => Http.post(`/profile/updateGallaryimageStatus/${id}/${isPrivate}`, {})
  
  getChannels = (userId: number, page: number, take: number, order: string = "DESC") => Http.get(`/chat/active/user/${userId}?page=${page}&take=${take}&order=${order}`);
  getAllChannels = (userId: number) => Http.get(`/chat/active/user/${userId}`);
}

export default new UserService();
