
import Vue from "vue";
import TimlineCard from "./TimelineCards/TimlineCards.vue";
import Navbar from "../../Navbar/NavbarComponent.vue";
import Undermenu from "../../Navbar/UnderMenu.vue";

export default Vue.extend({
  components: {
    TimlineCard,
    Navbar,
    Undermenu,
    // Register,
  },
  data() {
    return {
      username: "",
    };
  },
  computed: {
    getUsername() {
      console.log(this.$store.state.user.user.displayName);
      // return this.$store.state.user.displayName;
    },
  },
  mounted() {
    // this.getUsername;
  },
});
