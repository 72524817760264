
import Vue from "vue";
import Register from "../Register/Register.vue";

export default Vue.extend({
  props: {
    userVerify: Object,
    modalState: Boolean,
  },
  components: {
    Register,
  },
  data() {
    return {
      placeholder: "Loslegen",
      color: "white !important",
      width: "30% !important",
      margin: 0,
      user: this.userVerify,
      closeModals: false,
      open: false,
    };
  },
  methods: {
    closeModal() {
      this.closeModals = false;
      this.$emit("close", this.closeModals);
    },
    onClickOpen() {
      this.open = true;
      this.$emit("modal", this.open, !this.openLogin);
      this.openLogin = false;
    },
    closeRegisterModal() {
      this.open = false;
    },
  },
  computed: {
    createLinearRadient(): string {
      return "linear-gradient(305deg, #717CBD, #7b78bc, #9c4cc3)";
    },
  },
});
