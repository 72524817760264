
import Vue from 'vue';
import ChatterMessage from '@/components/Dashboard/NonUsers/Chatter/ChatterMessage.vue';
import UserInfo from './UserInfo.vue';
import UserProfile from './UserProfile.vue';

export default Vue.extend({
  components: {
    UserInfo,
    UserProfile,
    ChatterMessage,
  },
  data() {
    return {
    };
  },
});
