
import Vue from 'vue';
import { Coupon } from '@/types/coupon';
import Modal from '@/components/Modal/Modal.vue';
import PaymentModal from './PaymentModal.vue';
import PayPalButton from './PayPalButton.vue'; // Import PayPal component

export default Vue.extend({
  components: {
    Modal,
    PaymentModal,
    PayPalButton, // Register PayPal component
  },
  props: {
    coinsPriceObject: Object,
  },
  data() {
    return {
      coinsPrice: this.coinsPriceObject,
      paymentModal: false,
      showPaypal: false, // Controls PayPal button visibility
      sizeCard: {
        width: '600px',
        height: 'auto'
      },
    };
  },
  methods: {
    openPaymentModal() {
      this.paymentModal = true; // Show the payment modal
      this.showPaypal = true; // Show PayPal button
    },
    closePaymentModal() {
      this.paymentModal = false; // Hide the payment modal
      this.showPaypal = false; // Hide PayPal button when modal closes
    },
  },
});
