
import Vue from 'vue';
import UserServices from '@/Services/user.service';
import Card from './Card.vue';

export default Vue.extend({
  components: {
    Card,
  },
  data() {
    return {
      coinPricings: null,
      isProfile: null,
    };
  },
  mounted() {
    this.getProfiles();
  },
  methods: {
    getProfiles() {
      UserServices.getAllProfiles().then((res) => {
        this.isProfile = res.data.data;
      }).catch((err) => {
        console.log(err);
      });
    },
  },
});
