
import Vue from "vue";
import UserService from "@/Services/user.service";
import { backendUrl } from "@/helper/httpHelper";

export default Vue.extend({
  data() {
    return {
      user: null,
      backendUrl: backendUrl,
    };
  },
  methods: {
    getUser() {
      UserService.getUser(this.$route.params.id)
        .then((res) => {
          this.user = res.data;
          let date;

          if (this.user.isOnline) {
            this.user.isOnline = "Yes";
          } else {
            this.user.isOnline = "No";
          }
          if (this.user.isVerified) {
            this.user.isVerified = "Yes";
          } else {
            this.user.isVerified = "No";
          }

          date = this.user.createdAt.split("T")[0];
          this.user.createdAt = date;
          date = this.user.lastOnline.split("T")[0];
          this.user.lastOnline = date;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getUser();
  },
});
