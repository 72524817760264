
import Vue from "vue";
import { Gender } from "@/types/Gender";
import AuthService from "@/Services/auth.service";
import BirthdateInput from "../../../../Inputs/BirthDate/Birthdate.vue";

export default Vue.extend({
  components: {
    BirthdateInput,
  },
  data() {
    return {
      user: {
        birthDate: Date,
        country: "",
        zipCode: "",
        residence: "",
        gender: Gender,
        lookingFor: Gender,
      },
      stringGender: "",
      stringLookingFor: "",
    };
  },

  methods: {
    editUser() {
      const editUser = this.user;
      AuthService.updateUserProfile(editUser)
        .then((res) => {
          this.$toasted.success("Your account is edited successfully", {
            className: ["toasting", "success"],
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCurrentUser() {
      AuthService.currentUser()
        .then((res) => {
          this.user = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clear() {
      this.user.birthDate = Date;
      this.user.country = "";
      this.user.zipCode = "";
      this.user.residence = "";
      this.user.gender = Gender;
      this.user.lookingFor = Gender;
    },
    getDateFromChild(value) {
      this.user.birthDate = value;
    },
  },
  async mounted() {
    await this.getCurrentUser();
    if (this.user) {
      if (this.user.gender.Male === 0 && this.user.lookingFor.Female === 1) {
        this.stringGender = "Male";
        this.stringLookingFor = "Female";
      } else {
        this.stringGender = "Female";
        this.stringLookingFor = "Male";
      }
    }
  },
});
