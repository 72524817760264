import { Http } from '@/helper/httpHelper';


class MessageService {
    getWaitingMessages = () => Http.get('/chat/waiting');

    getActiveMessages = () => Http.get('/chat/active');

    loadMessages = (userId: number, profileId: number, pageNumber: number) => Http.get(`/messages/${userId}/${profileId}?page=${pageNumber}&take=20&order=DESC`);

    getChatId = (userId: number, profileId: number) => Http.get(`/chat/${userId}/${profileId}`);

    removeChats = () => Http.post('/chat/remove');

    messagesCountForChatter = (profileId: number, page: number, take: number, order: string = 'DESC', startDate?: string, endDate?: string) =>
        Http.get(`/messages/chatter/${profileId}?page=${page}&take=${take}&order=${order}${startDate != null ? `&firstDate=${startDate}` : ''}${endDate != null ? `&secondDate=${endDate}` : ''}`);

    chatterMessagesCountForOnlyOneUser = (chatId: number, userId: number) => Http.get(`/messages/chat/${chatId}/${userId}`);

    getWaitingMinutes = (chatterId?: number, order: string = 'DESC', page?: number, take?: number, startDate?: string, endDate?: string) => Http.get(`/messages/minutes/chatter/${chatterId}?page=${page}&take=${take}&order=${order}${startDate != null ? `&firstDate=${startDate}` : ''}${endDate != null ? `&secondDate=${endDate}` : ''}`);
    // getWaitingMinutes = (order: string = 'DESC', page?: number, take?: number) => Http.get(`/messages/minutes/chatter/chatterId`);

    getDashboardData = (chatterId: number) => Http.get(`/messages/dashboard/chatter/${chatterId}`);

    getDashboardClientStatsData = () => Http.get('/chat/dashboard/client/stats');

    getStatsForChatterMonthly = (chatterId: number) => Http.get(`/chat/dashboard/stats/${chatterId}`);

    getAllChats = (page: number, take: number, order: string = "DESC") => Http.get(`/chat/all/user?page=${page}&take=${take}&order=${order}`);
}

export default new MessageService();