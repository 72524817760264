
import Vue from "vue";
import AuthService from "@/Services/auth.service";
import { backendUrl } from "@/helper/httpHelper";

export default Vue.extend({
  data() {
    return {
      file: "",
      url: "",
      userProfilePic: null,
      user: null,
      reRender: true,
      backEndUrl: backendUrl,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    handleInput(file) {
      this.file = file;
      this.url = URL.createObjectURL(this.file);
      this.userProfilePic = this.file;
    },
    submitProfile() {
      this.reRender = false;
      let formData = new FormData();
      formData.append("mainImage", this.userProfilePic);

      AuthService.uploadPicture(formData)
        .then((res) => {
          console.log(res);
          this.file = "";
          this.url = "";
          this.$toasted.success("Profilbild erfolgreich hinzugefügt", {
            className: ["toasted", "success"],
          });
          this.reRender = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.user = this.currentUser;
  },
});
