
import Vue from "vue";
import AuthService from "@/Services/auth.service";
import Header from "../LandingHeader/Header.vue";

export default Vue.extend({
  components: {
    Header,
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      spinner: {
        paddingLeft: "1em",
        height: "20px",
      },
      showLoader: false,
    };
  },
  methods: {
    resetPassword() {
      this.showLoader = true;
      if (this.password && this.confirmPassword) {
        if (this.password.trim() === this.confirmPassword.trim()) {
          AuthService.resetPassword(this.getParam, this.password)
            .then((res) => {
              this.$toasted.success(
                "Ihr Passwort wurde erfolgreich zurückgesetzt",
                {
                  className: ["toasted", "success"],
                }
              );
              this.showLoader = false;
            })
            .catch((err) => {
              console.log(err);
              this.$toasted.error(
                "etwas ist schief gelaufen. Bitte versuche es erneut!",
                {
                  className: ["toasted", "error"],
                }
              );
              this.showLoader = false;
            });
        } else {
          this.$toasted.error(
            "Ihr Passwort und Ihr Bestätigungspasswort sollten übereinstimmen!",
            {
              className: ["toasted", "error"],
            }
          );
          this.showLoader = false;
        }
      } else {
        this.$toasted.info(
          "Sie müssen Ihr Passwort eingeben und das Passwort bestätigen",
          {
            className: ["toasting", "infoToast"],
          }
        );
        setTimeout(() => {
          this.showLoader = false;
        }, 2000);
      }
    },
  },
  computed: {
    getParam(): string {
      return this.$route.query.token;
    },
  },
});
