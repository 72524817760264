
import Vue from "vue";
import IO from "socket.io-client";
let socket;
import { backendUrl, frontendUrl, socketUrl } from "@/helper/httpHelper";
export default Vue.extend({
  data() {
    return {
      page: 2,
      loading: true,
      numberOfPages: 0,
      totalUsers: 0,
      options: {},
      headers: [
        { text: "Image", value: "image", width: "400px" },
        { text: "Client", value: "user.displayName", width: "300px" },
        { text: "Message", value: "lastMessage", width: "600px" },
        { text: "Profile", value: "profile.displayName", width: "300px" },
        { text: "Image", value: "profileImage", width: "100px" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      users: [],
      editedIndex: -1,
      editedItem: {
        name: "",
        displayName: "",
        createdAt: "",
        deletedAt: "",
        email: "",
        isProfile: false,
        coins: 0,
        birthdate: "",
        country: "",
        address: "",
        zipCode: "",
        gender: 0,
        lookingFor: 0,
        height: 0,
        hairColor: "",
        eyeColor: "",
        figure: "",
        smoker: "",
        seeking: "",
        job: "",
        language: "",
        flirtText: "",
      },
      defaultItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      clearFields: false,
      backendUrl: backendUrl,
    };
  },
  watch: {
    options: {
      handler() {
        this.getGlobalChat();
      },
      deep: true,
    },
  },
  methods: {
    addBoldFont(user) {
      if (!user.lastMessage?.seenChatter && user.lastMessage?.sendType == 0) {
        return "font-bold";
      }

      return "font-normal";
    },
    takeChat(chatId: number) {
      socket.emit("takeChat", { chat_id: chatId });

      socket.on("chatTaken", (res) => {
        if (res.result) {
          // window.location.replace("#/admin/activeDialog");
          this.$toasted.success("You succesfully have taken this chat", {
            className: ["toasted", "success"],
          });
          const { page, itemsPerPage } = this.options;

          socket.emit("chat/global", {
            page: page,
            order: "DESC",
            take: itemsPerPage,
          });
        } else {
          this.$toasted.error("This chat is taken try another one", {
            className: ["toasted", "error"],
          });
        }
      });
    },
    getGlobalChat() {
      let loading = true;
      const { page, itemsPerPage } = this.options;
      let pageNumber = page;
      if (itemsPerPage == -1) {
        socket.emit("chat/global");
        socket.on("chat/global/response", (res) => {
          console.log(res);
          this.users = res.chats.data;
          this.loading = false;
        });
      } else {
        socket.emit("chat/global", {
          page: page,
          order: "DESC",
          take: itemsPerPage,
        });
        socket.on("chat/global/response", (res) => {
          this.users = res.chats.data;
          this.page = res.chats.meta.page;
          this.totalUsers = res.chats.meta.itemCount;
          this.numberOfPages = res.chats.meta.pageCount;
          this.loading = false;
        });
      }
    },
  },
  mounted() {
    socket = IO(socketUrl, {
      extraHeaders: {
        Authorization: `${localStorage.getItem("Token")}`,
      },
    });
    socket.on("chat/message/new", (res) => {
      if (res.message?.chatId) {
        const chat = this.users.find((ch) => ch.id == res.message.chatId);
        if (chat) {
          this.users = this.users.filter((ch) => ch.id != chat.id);
          chat["lastMessage"] = res.message;
          this.users.unshift(chat);
          this.users.forEach((user) => {
            if (user.lastMessage?.bold) {
              this.addBoldFont(user);
              // this.users = user;
            }
          });
        } else {
          this.getGlobalChat();
        }
      }
    });
    socket.on("logoutResponse", (res) => {
      if (res.result) {
        const { page, itemsPerPage } = this.options;

        socket.emit("chat/global", {
          page: page,
          order: "DESC",
          take: itemsPerPage,
        });
      }
    });
  },
});
