
import Vue from "vue";
import Navbar from "@/components/Dashboard/Navbar/NavbarComponent.vue";
import UnderMenu from "@/components/Dashboard/Navbar/UnderMenu.vue";
import Modal from "@/components/Modal/Modal.vue";
import SendGift from "@/components/Dashboard/Pages/Messages/Chat/SendGift.vue";
import Emoji from "./Emoji.vue";
import IO from "socket.io-client";
let socket;
import UserService from "@/Services/user.service";
import MessageService from "@/Services/message.service";
import { mapActions } from "vuex";
import messageService from "@/Services/message.service";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en-US");
import { backendUrl, frontendUrl, socketUrl } from "@/helper/httpHelper";

export default Vue.extend({
  components: {
    Emoji,
    Modal,
    SendGift,
    Navbar,
    UnderMenu,
  },
  data() {
    return {
      chat: {
        message: "",
        img: "",
        profile_id: 0,
        id: 0,
      },
      msg: null,
      pageCount: 1,
      items: [],
      loading: false,
      emojis: null,
      closeEmoji: false,
      giftModal: false,
      sizeCard: {
        width: "400px",
        height: "400px !important",
      },
      gift: {},
      profile: {},
      displayChat: [],
      userId: 0,
      nextPageMessages: [],
      user: null,
      page: 1,
      lastPage: 0,
      isInit: true,
      timeNow: null,
      isProfileOnline: false,
      seenMessages: false,
      backendUrl: backendUrl,
    };
  },
  methods: {
    ...mapActions(["currentUser"]),
    onSendScrollToBotton() {
      let chatbox = document.getElementById("chatbox");
      chatbox.scrollTop = chatbox.scrollHeight;
    },
    send() {
      if (this.msg !== "") {
        this.chat.message = this.msg;
        this.chat.profile_id = this.profile.id;
        this.msg = null;
        this.emojis = null;
        this.$refs.emoji.clearEmojis();
        socket.emit("sendToProfile", this.chat);
        socket.emit("getCoinsForUser");
        // setTimeout(() => {
        //   socket.emit("chat/waiting");
        // }, 1000);
        this.onSendScrollToBotton();
      } else {
        this.$toasted.info(
          "You must type any word or emoji or gift in order to send a message",
          {
            className: ["toasted", "infoToast"],
          }
        );
      }
    },
    getEmojis(event) {
      this.emojis = event;
      if (this.msg === null) {
        this.msg = this.emojis;
      } else {
        this.msg += this.emojis;
      }
    },
    getGift(event) {
      this.gift = event;
      socket.emit("sendGiftToProfile", {
        gift_id: this.gift.id,
        profile_id: this.$route.params.id,
      });
      this.giftModal = false;
    },
    closeEmojiModal() {
      this.closeEmoji = true;
    },
    openGiftModal() {
      this.giftModal = true;
    },
    closeGiftModal() {
      this.giftModal = false;
    },
    sendGift(event) {
      this.gift = event;
    },
    async getProfile() {
      const response = await UserService.getUser(this.$route.params.id);
      this.profile = response.data;
    },
    formatTime() {
      let tempArray = [...this.displayChat];
      let formatter = timeAgo.format(new Date(this.displayChat.createdAt));
      tempArray[tempArray.length - 1].createdAt = formatter;
      this.displayChat[this.displayChat.length - 1].createdAt =
        tempArray[tempArray.length - 1].createdAt;
      // return this[tempArray.length -1];
    },
    async getMessages($state) {
      MessageService.loadMessages(
        +localStorage.getItem("UserId"),
        this.$route.params.id,
        this.page
      )
        .then((response) => {
          if (response.data.data.length > 0) {
            this.lastPage = response.data.meta.pageCount;
            response.data.data.forEach((message) => {
              this.displayChat.unshift(message);
            });
            this.displayChat.forEach((time) => {
              time.createdAt = time.createdAt.split("T")[0];
            });
            if (this.page - 1 === this.lastPage) {
              this.page = 2;
              $state.complete();
            } else {
              if (this.page === 3) {
                $state.complete();
              } else {
                this.page += 1;
              }
            }
            $state.loaded();
          } else {
            this.page = 2;
            $state.complete();
          }
        })
        .catch((e) => console.log(e));
    },
    getChatId() {
      messageService
        .getChatId(+localStorage.getItem("UserId"), this.$route.params.id)
        .then((res) => {
          this.chat.id = res.data.id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatTimeToDisplay(t) {
      // let date, time, sec;
      // date = t.split("T")[0];
      // time = t.split(".")[0];
      // sec = time.split("T")[1];
      // return date + "-" + sec;
      let time = new Date(t);
      return time.toLocaleString();
    },
  },
  async mounted() {
    this.user = await this.currentUser();
    await this.getChatId();
    await this.getProfile();
    socket = IO(socketUrl, {
      extraHeaders: {
        Authorization: `${localStorage.getItem("Token")}`,
      },
    });
    socket.on("messsage", async () => {
      this.send();
    });
    socket.on(`reciveFromServer`, (result) => {
      if (result.error) {
        this.$toasted.error(`${result.error}`, {
          className: ["toasted", "error"],
        });
      } else {
        if (result.message.chatId == this.chat.id) {
          this.displayChat.push(result.message);
          let formatter = timeAgo.format(new Date(result.message.createdAt));
          this.displayChat[this.displayChat.length - 1].createdAt = formatter;
          socket.emit("makeMessageSeenForUser", {
            message_id: result.message.id,
          });
        }
      }
    });
    socket.on("returnCoinsForUser", (result) => {
      if (result.user_id == this.user.id) {
        this.$store.commit("UPDATE_COINS", result.coins);
      }
    });
    socket.emit("checkIfUserIsOnline", { profile_id: this.profile.id });
    socket.on("returnProfileIsOnline", (result) => {
      this.isProfileOnline = result.isOnline.check;
    });
    socket.on(`responseGiftServer`, (result) => {
      if (result.message.chatId == this.chat.id) {
        this.displayChat.push(result.message);
      }
    });
    socket.on("makeMessageSeenForUserResponse", (result) => {
      this.seenMessages = result.result;
    });
  },
});
