
import Vue from "vue";
import UserService from "@/Services/user.service";
import { Role } from "@/types/Role";

export default Vue.extend({
  data() {
    return {
      users: [],
      headers: [
        { text: "Display Name", value: "displayName", width: "300px" },
        { text: "Email", value: "email", width: "300px" },
        { text: "Is Verified", value: "isVerified", width: "300px" },
        { text: "Birth Date", value: "birthDate", width: "300px" },
        { text: "Country", value: "country", width: "300px" },
        { text: "Address", value: "address", width: "300px" },
      ],
    };
  },
  methods: {
    getUsers() {
      UserService.getAllAgentOrAdminByRole(Role.Chatter)
        .then((res) => {
          this.users = res.data.data;
        })
        .catch((err) => {});
    },
    goToUserReports(id) {
      this.$router.push({
        name: "ChatterMinutesReports",
        params: { userId: id },
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getUsers();
    });
  },
});
