
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      switch1: false,
      dialog: false,
      gender: {
        male: false,
        female: false,
      },
      age: null,
      close: false,
    };
  },
  methods: {
    closeModal() {
      this.close = false;
      this.$emit("closeModal", this.close);
    },
    sendSearchOptions(e) {
      let gender = null;
      gender = this.gender.male ? 0 : null;
      gender = this.gender.female ? 1 : gender;
      gender = this.gender.male == this.gender.female ? null : gender;
      if (e.keyCode === 13) {
        this.$emit("sendSearchOptions", { gender, age: this.age });
        this.closeModal();
      } else {
        this.$emit("sendSearchOptions", { gender, age: this.age });
        this.closeModal();
      }
    },
  },
  watch: {
    "gender.male"() {
      this.sendSearchOptions();
    },
    "gender.female"() {
      this.sendSearchOptions();
    },
    age() {
      this.sendSearchOptions();
    },
  },
});
