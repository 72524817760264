
import Vue from 'vue';
import NotesSerivce from '@/Services/notes.service';
import { User } from '@/types/User';
import { createNote } from '@/types/createNote';

export default Vue.extend({
  props: {
    userId: Number,
  },
  data() {
    return {
      textInput: '',
      loading: false,
      currentUser: {} as User,
      idOfUser: this.userId,
      page: 1,
      notes: {},
    };
  },
  methods: {
    prevPage() {
      if (this.page === 1) return;
      --this.page;
      this.api();
    },
    nextPage() {
      ++this.page;
      this.api();
    },
    api() {
      this.loading = true;
      if (this.textInput) {
        const body = {
          message: this.textInput,
        } as createNote;
        NotesSerivce.createNote(this.idOfUser, body).then((res) => {
          this.$toasted.success('Note is saved successfully', {
            className: ['toasted', 'success'],
          });
          this.textInput = '';
        }).catch((err) => {
          console.log(err);
          this.$toasted.error('Note could not been saved. Try again!', {
            className: ['toasted', 'error'],
          });
        });
      } else {
        // this.$toasted.info('You should add a note in order to save it!!!', {
        //   className: ['toasted', 'info'],
        // });
        return '';
      }
      // setTimeout(() => {
      //   this.text.push('heeeeeeeeeeeeeeeeeeeeeeeeeey');
      //   this.loading = false;
      // }, 2000);
    },
    getNotes() {
      NotesSerivce.getNotes(this.idOfUser).then((res) => {
        this.notes = res.data;
        this.notes.forEach(time =>{
          time.createdAt = time.createdAt.split('T')[0];
        })
      }).catch((err) => {
        console.log(err);
      });
    },
  },
  computed: {
    getCurrentUser() :User {
      return this.$store.state.user.user;
    },
  },
  mounted() {
    this.currentUser = this.getCurrentUser;
    this.getNotes();
  },
});
