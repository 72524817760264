
import Vue from 'vue';

// type ButtonProps ={
//   labelName: string,
// }

export default Vue.extend({
  props: {
    labelName: String,
    styles: Object,
    isWorking: Boolean,
    disabled: Boolean,
    // extraStyle: Object,
    // propObject: {
    //   // type: Object as () => ButtonProps,
    // },
  },
  data() {
    return {
      placeholder: this.labelName,
      background: this.styles,
      isButtonWorking: this.isWorking,
      isDisabled: this.disabled,

      // extra: {
      //   color: this.extraStyle.color,
      //   width: this.extraStyle.width,
      //   margin: this.extraStyle.margin,
      // },
    };
  },
  methods: {
    onClickFunction() {
      this.$emit('onClickFunction');
    },
  },
});
