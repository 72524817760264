
import Vue from "vue";
import GiftService from "@/Services/gift.service";
import { Gift } from "@/types/gift";

export default Vue.extend({
  props: {
    toDispaly: Boolean,
  },
  data() {
    return {
      message: "Du hast noch keine Geschenke verschenkt.",
      ifExists: this.toDispaly,
      giftsSent: null as Gift,
      giftsReceived: null as Gift,
    };
  },
  methods: {
    // giftsCreated() {
    //   this.gifts.forEach(el => {
    //     this.createdGift.unshift(el.gift.createdAt.split("T")[0]);
    //   })
    // },
    async getReceivedGifts() {
      // if(this.userId)s
      GiftService.giftsAllUserReceived(this.Id)
        .then((res) => {
          this.giftsReceived = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getSentGifts() {
      GiftService.giftsUserSent(this.Id)
        .then((res) => {
          this.giftsSent = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    Id() {
      return this.$store.state.user.user.id;
    },
  },
  async mounted() {
    await this.Id;
    await this.getReceivedGifts();
    await this.getSentGifts();
  },
});
