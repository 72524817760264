
import Vue from "vue";
import UserService from "@/Services/user.service";

export default Vue.extend({
  props: {
    user: Object,
  },
  data() {
    return {
      userObj: this.user,
    };
  },
  methods: {
    verifyUser() {
      UserService.updateUser(this.userObj.id, {
        isVerified: this.userObj.isVerified,
      })
        .then((res) => {
          this.$toasted.success(`You've successfully verified user`, {
            className: ["toasted", "success"],
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
});
