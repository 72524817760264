
import Vue from "vue";
import PayableCard from "../PayableSection/PayabaleCard.vue";
import coinService from "@/Services/coinPricing.service";

export default Vue.extend({
  components: {
    PayableCard,
  },
  data() {
    return {
      pricingMethods: [],
      pricingMethods2: [],
    };
  },
  methods: {
    getPricingMethods() {
      coinService
        .getActiveCoinPricings()
        .then((res) => {
          this.pricingMethods = res.data;
          this.pricingMethods2.push(
            this.pricingMethods.find((priceMethod) => priceMethod.id == 13)
          );
          this.pricingMethods2.push(
            this.pricingMethods.find((priceMethod) => priceMethod.id == 14)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getPricingMethods();
  },
});
