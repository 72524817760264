
import Vue from "vue";
import UserProfile from "./UserProfile.vue";
import FilterSearch from "./FilterSearch.vue";
import UserService from "@/Services/user.service";
import Button from "../Inputs/Button/Button.vue";
import UnderMenu from "./UnderMenu.vue";
import IO from "socket.io-client";
let socket;
import { backendUrl, frontendUrl, socketUrl } from "@/helper/httpHelper";

export default Vue.extend({
  components: {
    FilterSearch,
    UserProfile,
    UnderMenu,
    Button,
  },
  data() {
    return {
      drawer: null,
      group: null,
      menus: [
        { title: "Home", icon: "mdi-view-dashboard" },
        { title: "About", icon: "mdi-forum" },
      ],
      placeholder: "Coins/Shop",
      onClickFilter: false,
      id: null,
      loading: false,
      items: [],
      search: null,
      select: null,
      gender: null,
      age: null,
      messageNr: null,
    };
  },
  mounted() {
    socket = IO(socketUrl, {
      extraHeaders: {
        Authorization: `${localStorage.getItem("Token")}`,
      },
    });
    this.getNotification();
    socket.on("chat/notifications/new", (res) => {
      this.getNotification();
    });
  },
  computed: {
    currentCoins() {
      return JSON.parse(JSON.stringify(this.$store.state));
    },
  },
  methods: {
    getNotification() {
      let socketId = localStorage.getItem("SocketId");
      socket.emit("countNotificationsForUser", { socket_id: socketId });
      socket.on(`countNotificationsForUser/${socketId}`, (res) => {
        if (res.data > 0) {
          this.messageNr = res.data;
        } else {
          this.messageNr = 0;
        }
      });
    },
    goToSearch() {
      this.$router.push({ name: "Search" });
    },
    toggleFilter() {
      this.onClickFilter = !this.onClickFilter;
    },
    searchForProfiles(query) {
      this.loading = true;
      if (query) {
        UserService.searchForProfiles(query, this.gender, this.age)
          .then((res) => {
            this.items = res.data;
          })
          .catch((err) => console.log(err));
        setTimeout(() => {
          this.loading = false;
        }, 500);
      } else {
        UserService.searchForProfiles("", this.gender, this.age)
          .then((res) => {
            this.items = res.data;
          })
          .catch((err) => console.log(err));
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
    searchOptions(payload) {
      this.gender = payload.gender;
      this.age = payload.age;
      if (this.gender && this.age) {
        this.searchForProfiles();
      }
    },
  },
  watch: {
    search(val) {
      val &&
        val !== this.select &&
        val?.length === 3 &&
        this.searchForProfiles(val);
    },
    select() {
      this.$router.push({
        name: "Profile",
        params: { id: this.select.id },
        replace: true,
      });
      location.reload();
    },
    searchOptions() {
      this.searchForProfiles(this.search);
    },
  },
});
