import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import LandingPage from '../components/LandingPage/LandingComponent.vue';
import Timeline from '../components/Dashboard/Pages/Timeline/Timeline.vue';
import Pictures from '../components/Dashboard/Pages/Pictures/Pictures.vue';
import Profile from '../components/Dashboard/Pages/Profile/Profile.vue';
import Friend from '../components/Dashboard/Pages/Friends/Friends.vue';
import Present from '../components/Dashboard/Pages/Presents/Presents.vue';
import MessageView from '../components/Dashboard/Pages/Messages/MessageView.vue';
import Messages from '../components/Dashboard/Pages/Messages/Messages.vue';
import Message from '../components/Dashboard/Pages/Messages/Chat/Message.vue';
import Shop from '../components/Dashboard/Pages/Shop/Shop.vue';
import Settings from '../components/Dashboard/Pages/Settings/Settings.vue';
import Admin from '../components/Dashboard/NonUsers/Admins/Admin.vue';
import AdminView from '../components/Dashboard/NonUsers/AdminView.vue';
import NotFound from '../components/NotFoundComponent.vue';
import VerifyAccount from '../components/LandingPage/LandingHeader/VerifyAccount.vue';
import { Role } from '@/types/Role';
import GiftPage from '@/components/Dashboard/NonUsers/Gifts/GiftPage.vue';
import CoinPricingPage from '@/components/Dashboard/NonUsers/CoinPricing/CoinPricingPage.vue';
import MassSpamsView from '@/components/Dashboard/NonUsers/MassSpams/MassSpamsView.vue';
import CouponPage from '@/components/Dashboard/NonUsers/Coupons/CouponPage.vue';
import Chatter from '@/components/Dashboard/NonUsers/Chatter/Chatter.vue';
import ChatterReports from "@/components/Dashboard/NonUsers/UserReports/ChatterReports.vue";
import ResetPassword from '@/components/LandingPage/Login/ResetPassword.vue';
import Waiting from '@/components/Dashboard/NonUsers/Waiting/Waiting.vue';
import ActiveDialog from '@/components/Dashboard/NonUsers/ActiveDialog/ActiveDialog.vue';
import Terms from '@/components/LandingPage/Terms.vue';
import Imprint from '@/components/LandingPage/Imprint.vue';
import AGB from '@/components/LandingPage/AGB.vue';
import PaymentsPage from "@/components/Dashboard/NonUsers/Payments/PaymentsPage.vue";
import AdvertisersPage from "@/components/Dashboard/NonUsers/AgentsViews/AdvertisersPage.vue";
import AdvertiserReports from "@/components/Dashboard/NonUsers/UserReports/AdvertiserReports.vue";
import ChattersPage from "@/components/Dashboard/NonUsers/AgentsViews/ChattersPage.vue";
import SuperAdminsPage from "@/components/Dashboard/NonUsers/AgentsViews/SuperAdminsPage.vue";
import AdminsPage from "@/components/Dashboard/NonUsers/AgentsViews/AdminsPage.vue";
import ProfilesPage from "@/components/Dashboard/NonUsers/AgentsViews/ProfilesPage.vue";
import MassSpamReports from "@/components/Dashboard/NonUsers/MassSpams/MassSpamReports.vue";
import Logins from '@/components/Dashboard/NonUsers/AuditLogs/Logins.vue';
import MomentCalculation from '@/components/Dashboard/NonUsers/MomentsCalculations/MomentCalculation.vue';
import ChatterMinutesCalculator from '@/components/Dashboard/NonUsers/UserReports/ChatterMinutesCalculator.vue';
import SearchPage from '@/components/Dashboard/Navbar/SearchPage.vue';
import GlobalRoom from '@/components/Dashboard/NonUsers/GlobalRoom/GlobalRoom.vue';
import Asa from '@/components/Dashboard/NonUsers/Asa/Asa.vue';
import ClientAbout from '@/components/Dashboard/NonUsers/ClientInfo/ClientAbout.vue';
import ClientChannels from '@/components/Dashboard/NonUsers/ClientInfo/ClientChannels.vue';
import ClientImages from '@/components/Dashboard/NonUsers/ClientInfo/ClientImages.vue';
import ClientInfo from '@/components/Dashboard/NonUsers/ClientInfo/ClientInfo.vue';
import ClientLocation from '@/components/Dashboard/NonUsers/ClientInfo/ClientLocation.vue';
import ClientReceivedGifts from '@/components/Dashboard/NonUsers/ClientInfo/ClientReceivedGifts.vue';
import ClientSentGifts from '@/components/Dashboard/NonUsers/ClientInfo/ClientSentGifts.vue';
import ClientPayments from '@/components/Dashboard/NonUsers/ClientInfo/ClientPayments.vue';
import ClientView from '@/components/Dashboard/NonUsers/ClientInfo/ClientView.vue';
import ClientActivity from '@/components/Dashboard/NonUsers/ClientInfo/ClientActivity.vue';
import Dashboard from '@/components/Dashboard/NonUsers/Dashboard/Dashboard.vue';
import VerifyInfo from '@/components/LandingPage/VerifyInfo.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '*',
    component: NotFound,
  },
  {
    path: '/',
    name: 'Landing',
    component: LandingPage,
    meta: {
      guest: true,
    },
  },
  {
    path: '/register/:referenceId?',
    name: 'Landing',
    component: LandingPage,
    meta: {
      guest: true,
    },
  },
  {
    path: '/verifyInfo',
    name: 'VerifyInfo',
    component: VerifyInfo,
  },
  {
    path: '/auth/verify/:verificatonCode',
    name: 'Verification',
    component: VerifyAccount,
  },
  {
    path: '/auth/forgotPassword/',
    name: 'ForgotPassword',
    component: ResetPassword,
  },
  {
    path: '/search',
    name: 'Search',
    component: SearchPage,
  },
  {
    path: '/timeline',
    name: 'Timeline',
    component: Timeline,
    meta: {
      requiresAuth: true,
      is_user: true,
      is_admin: false,
      authorize: [Role.User],
    },
  },
  {
    path: '/admin',
    name: 'AdminView',
    component: AdminView,
    redirect: { name: 'Admin' },
    meta: {
      authorize: [Role.SuperAdmin],
      requiresAuth: true,
      is_admin: true,
      is_user: false,
    },
    beforeEnter: (to, from, next) => {
      const role = localStorage.getItem('Role');
      // if (role == '0') {
      if (role != '5') {
        next()
      }
      //  else if (role == '1') {
      //   next()
      // } else if (role == '2') {
      //   next({ name: 'AdvertiserReports' })
      // } else if (role == '3') {
      //   next({ name: 'Dashboard' })
      // } else if (role == '4') {
      //   next()
      // } 
      else {
        next({ name: 'Timeline' });
      }
    },
    children: [
      {
        path: '/admin/users',
        name: 'Admin',
        component: Admin,
        meta: {
          authorize: [Role.SuperAdmin],
          requiresAuth: true,
          is_admin: true,
          is_user: false,
        },
      },
      {
        path: '/admin/gifts',
        name: 'Gifts',
        component: GiftPage,
        meta: {
          requiresAuth: true,
          authorize: [Role.Advertiser],
        },
      },
      {
        path: '/admin/coin-pricing',
        name: 'CoinPricings',
        component: CoinPricingPage,
        meta: {
          requiresAuth: true,
          authorize: [Role.Advertiser],
        },
      },
      {
        path: '/admin/payments',
        name: 'Payments',
        component: PaymentsPage,
        meta: {
          requiresAuth: true,
          authorize: [Role.Advertiser],
        },
      },
      {
        path: '/admin/coin-pricing',
        name: 'CoinPricings',
        component: CoinPricingPage,
        meta: {
          requiresAuth: true,
          authorize: [Role.Advertiser],
        },
      },
      {
        path: '/admin/mass-spam',
        name: 'MassSpams',
        component: MassSpamsView,
        meta: {
          requiresAuth: true,
          authorize: [Role.Advertiser],
        },
      },
      {
        path: '/admin/coupons',
        name: 'Coupons',
        component: CouponPage,
        meta: {
          requiresAuth: true,
          authorize: [Role.Advertiser],
        },
      },
      {
        path: '/admin/chatter/:userId/:profileId',
        name: 'Chatter',
        component: Chatter,
        meta: {
          requiresAuth: true,
          authorize: [Role.Chatter],
        },
      },
      {
        path: '/admin/chatters',
        name: 'Chatters',
        component: ChattersPage,
        meta: {
          requiresAuth: true,
          authorize: [Role.Chatter],
        },
      },
      {
        path: '/admin/chatter-reports/:userId?',
        name: 'ChatterReports',
        component: ChatterReports,
        meta: {
          requiresAuth: true,
          authorize: [Role.Chatter],
        },
      },
      {
        path: '/admin/super-admins',
        name: 'SuperAdmins',
        component: SuperAdminsPage,
        meta: {
          requiresAuth: true,
          authorize: [Role.Chatter],
        },
      },
      {
        path: '/admin/admins',
        name: 'AdminsView',
        component: AdminsPage,
        meta: {
          requiresAuth: true,
          authorize: [Role.Chatter],
        },
      },
      {
        path: '/admin/profiles',
        name: 'ProfilesView',
        component: ProfilesPage,
        meta: {
          requiresAuth: true,
          authorize: [Role.Chatter],
        },
      },
      {
        path: '/admin/advertisers',
        name: 'Advertisers',
        component: AdvertisersPage,
        meta: {
          requiresAuth: true,
          authorize: [Role.Chatter],
        },
      },
      {
        path: '/admin/advertiser-reports/:userId?',
        name: 'AdvertiserReports',
        component: AdvertiserReports,
        meta: {
          requiresAuth: true,
          authorize: [Role.Chatter],
        },
      },
      {
        path: '/admin/mass-spam-reports/:massSpamId',
        name: 'MassSpamReports',
        component: MassSpamReports,
        meta: {
          requiresAuth: true,
          authorize: [Role.Chatter],
        },
      },
      {
        path: '/admin/waiting',
        name: 'Waiting',
        component: Waiting,
        meta: {
          requiresAuth: true,
          authorize: [Role.Chatter],
        },
      },
      {
        path: '/admin/activeDialog',
        name: 'ActiveDialog',
        component: ActiveDialog,
        meta: {
          requiresAuth: true,
          authorize: [Role.Chatter],
        },
      },
      {
        path: '/admin/globalRoom',
        name: 'GlobalRoom',
        component: GlobalRoom,
        meta: {
          requiresAuth: true,
          authorize: [Role.Chatter],
        },
      },
      {
        path: '/admin/asa',
        name: 'Asa',
        component: Asa,
        meta: {
          requiresAuth: true,
          authorize: [Role.Chatter],
        },
      },
      {
        path: '/admin/logins',
        name: 'Logins',
        component: Logins,
        meta: {
          requiresAuth: true,
          authorize: [Role.Admin],
        },
      },
      {
        path: '/admin/minutes',
        name: 'Minutes',
        component: MomentCalculation,
        meta: {
          requiresAuth: true,
          authorize: [Role.Admin],
        },
      },
      {
        path: '/admin/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          requiresAuth: true,
          authorize: [Role.Chatter],
        },
      },
      {
        path: '/admin/minutes/:userId?',
        name: 'ChatterMinutesReports',
        component: ChatterMinutesCalculator,
        meta: {
          requiresAuth: true,
          authorize: [Role.Chatter],
        },
      },
      {
        path: '/admin/client',
        name: 'ClientView',
        component: ClientView,
        meta: {
          requiresAuth: true,
          authorize: [Role.Chatter],
        },
        children: [

          {
            path: '/admin/client/:id',
            name: 'ClientInfo',
            component: ClientInfo,
            meta: {
              requiresAuth: true,
              authorize: [Role.Chatter],
            },
          },
          {
            path: '/admin/client/:id/location',
            name: 'ClientLocation',
            component: ClientLocation,
            meta: {
              requiresAuth: true,
              authorize: [Role.Chatter],
            },
          },
          {
            path: '/admin/client/:id/payments',
            name: 'ClientPayments',
            component: ClientPayments,
            meta: {
              requiresAuth: true,
              authorize: [Role.Chatter],
            },
          },
          {
            path: '/admin/client/:id/channels',
            name: 'ClientChannels',
            component: ClientChannels,
            meta: {
              requiresAuth: true,
              authorize: [Role.Chatter],
            },
          },
          {
            path: '/admin/client/:id/sentGifts',
            name: 'ClientSentGifts',
            component: ClientSentGifts,
            meta: {
              requiresAuth: true,
              authorize: [Role.Chatter],
            },
          },
          {
            path: '/admin/client/:id/receivedGifts',
            name: 'ClientReceivedGifts',
            component: ClientReceivedGifts,
            meta: {
              requiresAuth: true,
              authorize: [Role.Chatter],
            },
          },
          {
            path: '/admin/client/:id/images',
            name: 'ClientImages',
            component: ClientImages,
            meta: {
              requiresAuth: true,
              authorize: [Role.Chatter],
            },
          },
          {
            path: '/admin/client/:id/activity',
            name: 'ClientActivity',
            component: ClientActivity,
            meta: {
              requiresAuth: true,
              authorize: [Role.Chatter],
            },
          },
        ],
      },
    ],
  },
  {
    path: '/pictures',
    name: 'Pictures',
    component: Pictures,
    meta: {
      requiresAuth: true,
      is_user: true,
      is_admin: false,
      authorize: [Role.User],
    },
  },
  {
    path: '/profile/:id',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true,
      is_user: true,
      is_admin: false,
      authorize: [Role.User],
    },
  },
  {
    path: '/friends',
    name: 'Friends',
    component: Friend,
    meta: {
      requiresAuth: true,
      is_user: true,
      is_admin: false,
      authorize: [Role.User],
    },
  },
  {
    path: '/message',
    name: 'MessageView',
    component: MessageView,
    redirect: { name: 'Message' },
    children: [
      {
        path: '/messages',
        name: 'Message',
        component: Messages,
        meta: {
          requiresAuth: true,
          is_user: true,
        },
      },
      {
        path: 'chat/:id',
        name: 'Chat',
        component: Message,
        meta: {
          requiresAuth: true,
          is_user: true,
          is_admin: false,
        },
      },
    ],
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop,
    meta: {
      requiresAuth: true,
      is_user: true,
      is_admin: false,
    },
  },
  {
    path: '/presents',
    name: 'Presents',
    component: Present,
    meta: {
      requiresAuth: true,
      is_user: true,
      is_admin: false,
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true,
      is_user: true,
      is_admin: false,
    },
  },
  {
    path: '/about',
    name: 'About',
  },
  {
    path: '/agb',
    name: 'AGB',
    component: AGB,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('Token');

  if (to && to.name == 'Landing') {
    if (token) {
      next({ name: 'Timeline' })
    }
  }
  if (to && to.meta) {
    if (to.meta.requiresAuth) {
      if (!token) {
        next({ name: 'Landing' });
      }
      next();
    } else {
      next();
    }
  }
});

export default router;

