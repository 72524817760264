import { Http } from '../helper/httpHelper';
import UserLoginDto from '@/types/Login';
import { User } from '@/types/User';

class AuthService {
  auth = (loginUser: UserLoginDto) => {
    return Http.post('/auth/login', loginUser);
  };

  verifyUser = (verificationCode: string) => {
    return Http.get(`/auth/verify/${verificationCode}`);
  };

  currentUser = () => {
    return Http.get('auth/currentUser');
  };

  forgotPassword = (email: string) => {
    return Http.post('auth/forgotPassword', {
      email,
    });
  };

  resetPassword = (token: string, password: string) => {
    return Http.post('/auth/resetPassword', { token, password });
  };

  updateCurrentUser = (user: User) => {
    return Http.post('auth/currentUser', user);
  }

  userLogOut = () => Http.post('/auth/logout');

  updateUserProfile = (userUpdate) => {
    const updateUserDto = turnUserIntoUpdateDto(userUpdate);
    let newUserUpdateObject = {};

    Object.entries(updateUserDto).forEach(([key, value]) => {
      if (value && value !== '') {
        newUserUpdateObject = { ...newUserUpdateObject, [key]: value };
      }
    });

    return Http.put('/auth/update-profile', newUserUpdateObject);
  }

  uploadPicture = (userProfile) => Http.post('/auth/upload', userProfile);
}

const turnUserIntoUpdateDto = (user) => {
  return {
    displayName: user.displayName,
    email: user.email,
    password: user.password,
    country: user.country,
    address: user.address,
    zipCode: user.zipCode,
    gender: user.gender,
    lookingFor: user.lookingFor,
    height: user.height,
    hairColour: user.hairColour,
    eyeColour: user.eyeColour,
    figure: user.figure,
    smoker: user.smoker,
    seeking: user.seeking,
    relationShipStatus: user.relationShipStatus,
    children: user.children,
    job: user.job,
    language: user.language,
    flirtText: user.flirtText,
  }

}
export default new AuthService();
