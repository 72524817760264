
import Vue from 'vue';

export default Vue.extend({
  props: {
    modalHeader: String,
    isModalShown: Boolean,
    onSubmit: Function,
    cardSize: Object,
    buttonName: String,
  },
  components: {},
  data() {
    return {
      close: this.isModalShown,
      title: this.modalHeader,
      modalSize: this.cardSize,
      clickedSave: false,
      buttonNaming: this.buttonName,
      profile: {},
    };
  },
  methods: {
    closeModalComp() {
      this.close = true;
      this.$emit('closeModal', this.close);
    },
    submitAgentForm() {
      this.onSubmit();
      this.closeModalComp();
    },
  },
});
