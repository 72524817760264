
import Vue from "vue";
import SettingsEdit from "./SettingsEdit/SettingsEdit.vue";
import Navbar from "../../Navbar/NavbarComponent.vue";
import Undermenu from "../../Navbar/UnderMenu.vue";

export default Vue.extend({
  components: {
    SettingsEdit,
    Navbar,
    Undermenu,
  },
});
