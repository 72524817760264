
import Vue from "vue";
import Navbar from "@/components/Dashboard/Navbar/NavbarComponent.vue";
import UnderMenu from "@/components/Dashboard/Navbar/UnderMenu.vue";
import Modal from "@/components/Modal/Modal.vue";
import SendGift from "@/components/Dashboard/Pages/Messages/Chat/SendGift.vue";
import Emoji from "@/components/Dashboard/Pages/Messages/Chat/Emoji.vue";
import IO from "socket.io-client";
let socket;
import UserService from "@/Services/user.service";
import messageService from "@/Services/message.service";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en-US");
import { backendUrl, frontendUrl, socketUrl } from "@/helper/httpHelper";

export default Vue.extend({
  components: {
    Emoji,
    Modal,
    SendGift,
    Navbar,
    UnderMenu,
  },
  data() {
    return {
      chat: {
        message: "",
        profile_id: 0,
        user_id: 0,
        id: null,
      },
      msg: null,
      pageCount: 1,
      items: [],
      loading: false,
      emojis: null,
      closeEmoji: false,
      giftModal: false,
      sizeCard: {
        width: "400px",
        height: "auto !important",
      },
      gift: '<img src="" />',
      user: {},
      profile: {},
      displayChat: [],
      nextPageMessages: [],
      page: 1,
      lastPage: 0,
      isInit: true,
      isUserOnline: false,
      seenMessage: true,
      friendRequest: null,
      insAndOuts: null,
      backendUrl: backendUrl,
    };
  },
  methods: {
    sendToWaiting() {
      socket.emit("sendToWaiting", { chat_id: this.chat.id });
      socket.on("chatWaiting", (res) => {
        if (res.result) {
          this.$router.push({ name: "Waiting" });
        }
      });
    },
    onSendScrollToBottom() {
      let chatbox = document.getElementById("chatbox");
      console.log(window.scrollY);
      window.scrollTo({
        top: chatbox.offsetHeight,
        left: 0,
      });
      console.log(chatbox.offsetHeight);
    },
    send() {
      let str = this.msg;
      let msgWithoutEmojis = null;
      msgWithoutEmojis = str.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, "");
      if (msgWithoutEmojis.length > 80) {
        this.chat.message = this.msg;
        this.chat.profile_id = this.profile.id;
        this.chat.user_id = this.user.id;
        this.msg = null;
        this.emojis = null;
        this.$refs.emoji.clearEmojis();
        socket.emit("sendToUser", this.chat);
        socket.emit("chat/waiting");
        // socket.emit("makeMessageSeenForChatter", {
        //   message_id: this.chat.message.id,
        // });
        // socket.emit("makeMessageSeenForChatter", {
        //   message_id: this.displayChat[this.displayChat?.length - 1]?.id,
        // });
      } else {
        this.$toasted.info("You cant message more than 80 characters", {
          className: ["toasted", "infoToast"],
        });
      }
      // this.onSendScrollToBottom();
      let chatbox = document.getElementById("chatbox");
      chatbox.scrollTop = chatbox.scrollHeight;
      window.scrollTo({
        top: chatbox.offsetHeight,
        left: 0,
        behavior: "smooth",
      });
      // console.log((chatbox.scrollTop = chatbox.scrollHeight));
    },
    sendGift(event) {
      socket.emit("sendGiftToProfile", {
        gift_id: 2,
        profile_id: this.$route.params.profileId,
      });
    },
    getEmojis(event) {
      this.emojis = event;
      if (this.msg === null) {
        this.msg = this.emojis;
      } else {
        this.msg += this.emojis;
      }
    },
    getGift(event) {
      this.gift = event;
      socket.emit("sendGiftToUser", {
        gift_id: this.gift.id,
        user_id: this.$route.params.userId,
        profile_id: this.$route.params.profileId,
      });
      this.giftModal = false;
    },
    closeEmojiModal() {
      this.closeEmoji = true;
    },
    openGiftModal() {
      this.giftModal = true;
    },
    closeGiftModal() {
      this.giftModal = false;
    },
    async getUser() {
      const response = await UserService.getUser(this.$route.params.userId);
      this.user = response.data;
    },
    async getProfile() {
      const response = await UserService.getUser(this.$route.params.profileId);
      this.profile = response.data;
    },
    async getFriendRequest() {
      UserService.getFriendRequestFromUser(
        this.$route.params.userId,
        this.$route.params.profileId
      )
        .then((res) => {
          this.friendRequest = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    countMessagesForChatter() {
      messageService
        .chatterMessagesCountForOnlyOneUser(
          this.chat.id,
          this.$route.params.userId
        )
        .then((res) => {
          this.insAndOuts = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    accepFriendRequest() {
      UserService.approveFriendRequest(this.friendRequest.id).then((res) => {
        console.log(res);
        this.$toasted.success(
          `You are now friends with ${this.user.displayName}`,
          {
            className: ["toasted", "success"],
          }
        );
      });
    },
    loadMessages: async function ($state) {
      messageService
        .loadMessages(
          this.$route.params.userId,
          this.$route.params.profileId,
          this.page
        )
        .then((response) => {
          if (response.data.data.length > 0) {
            this.lastPage = response.data.meta.pageCount;
            response.data.data.forEach((message) => {
              this.displayChat.unshift(message);
            });
            this.displayChat.forEach((time) => {
              time.createdAt = time.createdAt.split("T")[0];
            });
            if (this.page - 1 === this.lastPage) {
              this.page = 2;
              $state.complete();
            } else {
              this.page += 1;
            }
            $state.loaded();
          } else {
            // socket.emit("makeMessageSeenForChatter", {
            //   message_id: this.displayChat[this.displayChat?.length - 1]?.id,
            // });
            this.page = 2;
            $state.complete();
          }
        })
        .catch((e) => console.log(e));
      this.onSendScrollToBottom();
    },
    getChatId() {
      messageService
        .getChatId(this.$route.params.userId, this.$route.params.profileId)
        .then((res) => {
          this.chat.id = res.data.id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatTime(t) {
      // let date, time, sec;
      // date = t.split("T")[0];
      // time = t.split(".")[0];
      // sec = time.split("T")[1];
      // return date + "-" + sec;
      let time = new Date(t);
      return time.toLocaleString();
    },
  },
  async mounted() {
    await this.getChatId();
    this.getUser();
    await this.getProfile();
    await this.getFriendRequest();
    await this.countMessagesForChatter();
    socket = IO(socketUrl, {
      extraHeaders: {
        Authorization: `${localStorage.getItem("Token")}`,
      },
    });

    socket.on("reciveFromServer", (result) => {
      this.seenMessage = false;
      if (result.message.chatId == this.chat.id) {
        this.displayChat.push(result.message);
        // let formatter = timeAgo.format(new Date(result.message.createdAt));
        let timeandDate = new Date(result.message.createdAt);
        this.displayChat[this.displayChat.length - 1].createdAt =
          timeandDate.toLocaleString();
        // formatter.toLocaleString();
        // socket.emit("makeMessageSeenForChatter", {
        //   message_id: result.message.id,
        // });
      }
    });
    socket.emit("checkIfUserIsOnline", { user_id: this.$route.params.userId });
    socket.on("returnUserIsOnline", (result) => {
      this.isUserOnline = result.isOnline.check;
    });
    socket.on(`responseGiftServer`, (result) => {
      if (result.message.chatId == this.chat.id) {
        this.displayChat.push(result.message);
      }
    });

    this.onSendScrollToBottom();
    // socket.on("makeMessageSeenForChatter", (result) => {
    //   this.seenMessage = result.result;
    // });
  },
});
