
import Vue from "vue";

export default Vue.extend({
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 2,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      current: this.currentPage,
    };
  },
  computed: {
    startPage() {
      if (this.current === 1) {
        return 1;
      }

      if (this.current === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons;
      }

      return this.current - 1;
    },

    endPage() {
      return Math.min(this.startPage + this.maxVisibleButtons, this.totalPages);
    },

    pages() {
      const range = [];
      for (
        let i = this.current;
        i <=
        Math.min(this.current + this.maxVisibleButtons - 1, this.totalPages);
        i++
      ) {
        range.push({
          name: i,
          isDisabled: i === this.current,
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.current === 1;
    },
    isInLastPage() {
      return this.current === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit("pageChanged", 1);
      this.current = 1;
    },
    onClickPreviousPage() {
      this.current -= 1;
      this.$emit("pageChanged", this.current);
    },
    onClickPage(page) {
      this.$emit("pageChanged", page);
      this.current = page;
    },
    onClickNextPage() {
      this.current += 1;
      this.$emit("pageChanged", this.current);
    },
    onClickLastPage() {
      this.current = this.totalPages;
      this.$emit("pageChanged", this.totalPages);
    },
    isPageActive(page) {
      return this.current === page;
    },
  },
});
