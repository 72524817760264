
import Vue from "vue";
import { backendUrl } from "@/helper/httpHelper";

export default Vue.extend({
  props: {
    toDisplay: Boolean,
    profile: Object,
  },
  data() {
    return {
      ifText: this.toDisplay,
      profileObj: this.profile,
      backendUrl: backendUrl,
    };
  },
});
