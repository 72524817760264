
import Vue from "vue";
import ProfilePicture from "./ProfilePicture/ProfilePicture.vue";
import ManagePictures from "./ManagePictures/ManagePictures.vue";
import Navbar from "../../Navbar/NavbarComponent.vue";
import Undermenu from "../../Navbar/UnderMenu.vue";

export default Vue.extend({
  components: {
    ProfilePicture,
    ManagePictures,
    Navbar,
    Undermenu,
  },
});
