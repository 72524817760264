
import Vue from "vue";

export default Vue.extend({
  props: {
    labelName: String,
  },
  data() {
    return {
      activePicker: null,
      date: null,
      menu: false,
      label: this.labelName,
      validDate: "",
    };
  },
  methods: {
    getDate() {
      this.$emit("passDate", this.date);
    },
  },
  computed: {
    isOver18() {
      const date18YrsAgo = new Date();
      date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);
      return date18YrsAgo.toISOString();
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  mounted() {
    // this.isOver18();
  },
});
