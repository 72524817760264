
import Vue from "vue";
import UserService from "@/Services/user.service";
import { backendUrl } from "@/helper/httpHelper";
import { uploadImage } from "@/types/createProfile";

export default Vue.extend({
  props: {
    profile: Object,
    getUsers: Function,
  },
  data() {
    return {
      profileObj: this.profile,
      newProfile: { ...this.profile },
      galleryEdit: JSON.parse(JSON.stringify(this.profile.gallery)),
      registerDate: "",
      lastUpdated: "",
      userProfilePic: null,
      url: "",
      backEndUrl: backendUrl,
      file: "",
      files: "",
    };
  },
  methods: {
    updateGallaryimageStatus(userId, isPrivate){
      UserService.updateGallaryimageStatus(userId, isPrivate).then((res) => {
        this.$toasted.success("Image Status updated successfully", {
            className: ["toasted", "success"],
          });
      });
    },
    updateProfile() {
      let tempData = {};

      for (let keys in this.newProfile) {
        if (
          this.profileObj[keys] &&
          this.newProfile[keys] != this.profileObj[keys]
        ) {
          tempData[keys] = this.profileObj[keys];
        }
      }

      UserService.updateUser(this.profileObj.id, tempData)
        .then((res) => {
          this.$toasted.success("Profile is successfully edited", {
            className: ["toasted", "success"],
          });
          this.getUsers();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handlePicture(file) {
      this.file = file;
      this.url = URL.createObjectURL(this.file);
    },
    addDelete(idx) {
      this.profileObj.gallery[idx]["deletedAt"] = true;
    },
    handleGallery(files) {
      this.files = files;
    },
    updateProfilePicture() {
      let formData = new FormData();
      formData.append("mainImage", this.file);

      UserService.uploadProfilePicture(this.profileObj.id, formData)
        .then((res) => {
          console.log(res);
          this.file = "";
          this.url = "";
          this.$toasted.success("Profile Picture uploaded succsessfully", {
            className: ["toasted", "success"],
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateGallery() {
      let tempData = [];
      let formData = new FormData();
      const newPictures = this.files as uploadImage;
      for (const [idx, img] of this.galleryEdit.entries()) {
        for (let keys in img) {
          if (
            this.profileObj.gallery[idx][keys] &&
            img[keys] != this.profileObj.gallery[idx][keys]
          ) {
            tempData.push({
              id: img.id,
              isPrivate: this.profileObj.gallery[idx][keys],
              isDeleted: this.profileObj.gallery[idx]["deletedAt"],
            });
          }
        }
      }

      if (this.files.length > 0) {
        Object.entries(newPictures).forEach(([key, value]) => {
          formData.append("gallery", value);
        });
        UserService.uploadProfilePicture(this.profileObj.id, formData)
          .then((res) => {
            this.$toasted.success("Gallery is updated", {
              className: ["toasted", "success"],
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        UserService.uploadProfilePicture(this.profileObj.id, {
          galleryEdit: tempData,
        })
          .then((res) => {
            this.$toasted.success("Gallery is updated", {
              className: ["toasted", "success"],
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  mounted() {
    let date = this.profileObj.createdAt.split("T");
    let updated = this.profileObj.updatedAt.split("T");
    this.registerDate = date[0];
    this.lastUpdated = updated[0];
  },
});
