
import Vue from 'vue';
import CouponService from '@/Services/coupon.service';
import { Coupon } from '@/types/coupon';

export default Vue.extend({
  components: {},
  props: {
    editCoupon: Object,
  },
  data() {
    return {
      coupon: this.editCoupon ?? {
        // name: '',
        // coinPrice: 0,
        // price: 0,
        // isActive: false,
        // coinAmount: 0,
        // hasPaypal: false,
        // hasSofort: false,
        // hasCall2Pay: false,
        // hasMobilePay: false,
        // hasPayDirekt: false,
        // hasMaestroCard: false,
        // hasVisa: false,
        // hasMasterCard: false,
        // hasKlarna: false,
        code: '',
        percentage: 0,
        isActive: false,
      } as Coupon,
    };
  },
  methods: {
    async createCoinPricing() {
      if (this.coupon.id) {
        // return CouponService.updateCoinPricing(this.coupon);
      }
      // return CouponService.createCoinPricing(this.coupon);
    },
    clearFields() {
      this.coupon = {
        code: '',
        percentage: 0,
        isActive: false,
      } as Coupon;
    },
  },
});
