
import Vue from 'vue';
import UserService from '@/Services/user.service';
import Modal from "@/components/Modal/Modal.vue";
import SliderForm from "@/components/Dashboard/NonUsers/SliderForm.vue";
import CreateAgent from "@/components/Dashboard/NonUsers/Agents/CreateAgent.vue";
import {Role} from "@/types/Role";

export default Vue.extend({
  components: {
    Modal,
    // CreateProfile,
    SliderForm,
    CreateAgent,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dback: true,
    headers: [
      { text: 'Display Name', value: 'displayName', width: '300px' },
      { text: 'Email', value: 'email', width: '300px' },
      { text: 'Is Verified', value: 'isVerified', width: '300px' },
      { text: 'Birth Date', value: 'birthDate', width: '300px' },
      { text: 'Country', value: 'country', width: '300px' },
      { text: 'Address', value: 'address', width: '300px' },
    ],
    desserts: [],
    coinPricings: [],
    users: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      displayName: '',
      createdAt: '',
      deletedAt: '',
      email: '',
      isProfile: false,
      coins: 0,
      birthdate: '',
      country: '',
      address: '',
      zipCode: '',
      gender: 0,
      lookingFor: 0,
      height: 0,
      hairColor: '',
      eyeColor: '',
      figure: '',
      smoker: '',
      seeking: '',
      job: '',
      language: '',
      flirtText: '',
    },
    defaultItem: {
      name: '',
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    openModal: false,
    openAModal: false,
    clearFields: false,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },
  },
  watch: {
    dialog(val) {
      val || this.showModal();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getUsers();
    });
  },
  methods: {
    openAgentModal() {
      this.openAModal = true;
    },
    closeCoinPricingModal() {
      this.openAModal = false;
    },
    openModalComponent() {
      this.openModal = true;
    },
    closeModalComp() {
      this.openModal = false;
    },
    closeAgentModal() {
      this.openAModal = false;
    },
    affectDeletedRow(item) {
      if (item.deletedAt) {
        return 'deleted--background';
      }
      return 'normal--background';
    },
    getUsers() {
      UserService.getAllAgentOrAdminByRole(Role.Chatter).then((res) => {
        this.users = res.data.data;
      }).catch((err) => {
      });
    },
    editItem(item) {
      this.editedIndex = this.coinPricings.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.coinPricings.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },

    deleteItemConfirm(item) {
      UserService.deleteUser(this.editedItem.id).then((res) => {
        this.getUsers();
      }).catch((err) => {
        console.log(err);
      });
      this.closeDelete();
    },

    showModal() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.coinPricings[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.showModal();
    },
    submit() {
      this.$refs.sliderForm.submitProfile();
    },
    submitAgent() {
      this.$refs.myAgent.createAgent();
    },
    goToUserReports(id){
      this.$router.push({
        name: 'ChatterReports',
        params: { userId: id }
      });
    }
  },
});
