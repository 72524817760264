
import Vue from "vue";
import CoinPricingService from "@/Services/coinPricing.service";
import Modal from "@/components/Modal/Modal.vue";
import CreateCoinPricing from "@/components/Dashboard/NonUsers/CoinPricing/CreateCoinPricing/CreateCoinPricing.vue";
import { CoinPricing } from "@/types/coinPricing";

export default Vue.extend({
  components: {
    CreateCoinPricing,
    Modal,
  },
  data: () => ({
    dialogDelete: false,
    dback: true,
    headers: [
      { text: "Coin Pricing Name", value: "name", width: "300px" },
      { text: "Coin Pricing Price", value: "price", width: "300px" },
      { text: "Coin Amount", value: "coinAmount", width: "300px" },
      { text: "Active", value: "isActive", width: "300px" },
      { text: "Sold", value: "payments", width: "300px" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],
    coinPricings: null,
    editedIndex: -1,
    editedItem: {} as CoinPricing,
    openModal: false,
    openAModal: false,
    clearFields: false,
    cardSize: {
      height: "600px !important",
    },
    page: 2,
    loading: true,
    numberOfPages: 0,
    totalUsers: 0,
    options: {},
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.showModal();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getCoinPricings();
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getCoinPricings();
    });
  },

  methods: {
    openCoinPricingModal() {
      this.openAModal = true;
    },
    closeCoinPricingModal() {
      this.openAModal = false;
    },
    openModalComponent() {
      this.openModal = true;
    },
    closeModalComp() {
      this.openModal = false;
    },
    affectDeletedRow(item) {
      if (item.deletedAt) {
        return "deleted--background";
      }

      return "normal--background";
    },
    getCoinPricings() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      let pageNumber = page;
      if (itemsPerPage == -1) {
        CoinPricingService.getAllCoinPricings()
          .then((res) => {
            this.coinPricings = res.data.data;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        CoinPricingService.getCoinPricings(pageNumber, itemsPerPage)
          .then((res) => {
            this.coinPricings = res.data.data;
            this.page = res.data.meta.page;
            this.totalUsers = res.data.meta.itemCount;
            this.numberOfPages = res.data.meta.pageCount;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    editItem(item) {
      this.editedIndex = this.coinPricings.indexOf(item);
      this.editedItem = { ...item };
      this.openAModal = true;
    },

    deleteItem(item) {
      this.editedIndex = this.coinPricings.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      CoinPricingService.deleteCoinPricing(this.editedItem.id)
        .then(() => {
          this.getCoinPricings();
        })
        .catch((err) => {
          console.log(err);
        });
      this.closeDelete();
    },

    showModal() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.coinPricings[this.editedIndex], this.editedItem);
      }

      this.showModal();
    },
    async createCoinPricing() {
      if (this.$refs.createCoinPricing) {
        this.$refs.createCoinPricing
          .createCoinPricing()
          .then(() => {
            this.getCoinPricings();
            // this.$refs.createCoinPricing.clearFields();
            this.$toasted.success("Coin Pricing created successfully", {
              className: ["toasting", "success"],
            });
            this.$router.go();
          })
          .catch(() => {
            this.$toasted.error("Coin Pricing failed creating!", {
              className: ["toasting", "error"],
            });
          });
      }
    },
    updateCoinPricingActiveStatus(giftId: number) {
      CoinPricingService.updateCoinPricingActiveStatus(giftId)
        .then(() => {
          this.$toasted.success("Coin Pricing active status changed!", {
            className: ["toasting", "error"],
          });
        })
        .catch(() => {
          this.$toasted.error("Failed activating/disactivating coin pricing!", {
            className: ["toasting", "error"],
          });
        });
    },
    goToPayments(id) {
      this.$router.push({
        name: "Payments",
        query: { coinPricingId: id },
      });
    },
  },
});
