import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { UserState } from './types';
import { User } from '@/types/User';

export const getters: GetterTree<UserState, RootState> = {
  getUserInfo(state): object {
    return state;
  },
  getCurrentUser(state): User | undefined {
    return state.user;
  },
  getSavedGallery(state): any{
    return state.gallery
  }
};
