
import Vue from "vue";
import coinService from "@/Services/coinPricing.service";
import PayableCard from "./PayabaleCard.vue";

export default Vue.extend({
  components: {
    PayableCard,
  },
  data() {
    return {
      pricingMethods: [],
      pricingMethods2: [],
    };
  },
  methods: {
    getPricingMethods() {
      coinService
        .getActiveCoinPricings()
        .then((res) => {
          this.pricingMethods = res.data;
          // this.pricingMethods2.push(
          //   this.pricingMethods.find((priceMethod) => priceMethod.id == 10)
          // );
          // this.pricingMethods2.push(
          //   this.pricingMethods.find((priceMethod) => priceMethod.id == 11)
          // );
          this.pricingMethods = this.pricingMethods.filter(
            (pM) => pM.id != 13 && pM.id != 14
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getPricingMethods();
  },
});
