
import Vue from "vue";
import UserService from "@/Services/user.service";
import { backendUrl } from "@/helper/httpHelper";
import VueImg from "v-img";

Vue.use(VueImg, {
  altAsTitle: true,
  sourceButton: false,
  thumbnails: true,
  changed: "click",
});

export default Vue.extend({
  props: {
    gallery: Array,
  },
  data() {
    return {
      galleryPictures: this.gallery,
      fullScreenImg: false,
      closeSign: false,
      isFriend: false,
      backendUrl: backendUrl,
      friend: null,
    };
  },
  methods: {
    checkIfFriend() {
      UserService.checkIfFriends(this.$route.params.id)
        .then((res) => {
          this.friend = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  async mounted() {
    await this.checkIfFriend();
  },
});
