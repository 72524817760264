
import Vue from "vue";
import { backendUrl } from "@/helper/httpHelper";
import UserService from "@/Services/user.service";
import AuthService from "@/Services/auth.service";

export default Vue.extend({
  props: {
    name: String,
    image: Object,
    userId: Number,
  },
  data() {
    return {
      username: this.name,
      profileImg: this.image,
      id: this.userId,
      backendUrl: backendUrl,
      currentUser: null,
      friend: null,
      ifFriend: false,
      friendButton: "Freundschaftsanfrage",
      dissabledButton: false,
      reRender: true,
    };
  },
  computed: {
    isFriendDissableButton() {
      if (this.friend) {
        return (this.dissabledButton = true);
      }
    },
  },
  methods: {
    sendFriendRequest() {
      this.reRender = false;
      if (this.currentUser.coins >= 20) {
        UserService.addFriendRequest(this.id)
          .then((res) => {
            this.$toasted.success("Freundschaftsanfrage wurde gesendet!", {
              className: ["toasted", "success"],
            });
            this.friendButton = "Freunde";
            this.dissabledButton = true;
            this.checkIfFriend();
            this.reRender = true;
          })
          .catch((err) => {
            console.log(err);
            this.$toasted.error("etwas ist schief gelaufen.", {
              className: ["toasted", "error"],
            });
          });
      } else {
        this.$toasted.error("Not enough coins to add her as a friend.", {
          className: ["toasted", "error"],
        });
      }
    },
    getCurrentUser() {
      AuthService.currentUser()
        .then((res) => {
          this.currentUser = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkIfFriend() {
      UserService.checkIfFriends(this.id)
        .then((res) => {
          this.friend = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  async mounted() {
    await this.getCurrentUser();
    await this.checkIfFriend();
  },
});
