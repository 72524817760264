
import Vue from "vue";
import messageService from "@/Services/message.service";
import authService from "@/Services/auth.service";
import socketIO from "socket.io-client";
import { backendUrl, frontendUrl, socketUrl } from "@/helper/httpHelper";
let socket;

export default Vue.extend({
  data() {
    return {
      stats: {},
      loading: false,
      role: null,
    };
  },
  methods: {
    logOut() {
      // messageService
      //   .removeChats()
      //   .then(async (res) => {
      //     setTimeout(() => {
      //       socket.emit("chat/waiting");
      //     }, 1000);
      //
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      socket.emit("removeChats");
      socket.on("logoutResponse", (res) => {
        if (res.result) {
          authService.userLogOut();
          localStorage.clear();
          this.$router.push({ name: "Landing" });
        }
      });
    },
    getStatsForChatter() {
      messageService
        .getStatsForChatterMonthly(
          this.currentUserId?.id ?? localStorage.getItem("UserId")
        )
        .then((res) => {
          this.stats = res.data;
          this.loading = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    // currentUser() {
    //   return this.$store.state.user.user.role;
    // },
    currentUserId() {
      return this.$store.state.user.user;
    },
  },
  watch: {
    currentUserId: {
      handler(newVal) {
        if (newVal) {
          this.getStatsForChatter();
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    socket = socketIO(socketUrl, {
      extraHeaders: {
        Authorization: `${localStorage.getItem("Token")}`,
      },
    });
    this.role = localStorage.getItem("Role");
    console.log(this.role);
    this.getStatsForChatter();
  },
});
