
import Vue from "vue";
import AuthService from "@/Services/auth.service";
import LoginDTO from "../../../types/Login";
import router from "../../../router/index";
import { Role } from "@/types/Role";

export default Vue.extend({
  data() {
    return {
      user: {
        email: "",
        password: "",
      } as LoginDTO,
      isVerified: false,
      showLoader: false,
      spinner: {
        paddingLeft: "0em",
        paddingBottom: "2em",
      },
      openForgot: false,
    };
  },
  methods: {
    loginAction(e) {
      this.showLoader = true;
      const data = {
        email: this.user.email,
        password: this.user.password,
      };
      if (this.user.email && this.user.password) {
        AuthService.auth(data)
          .then((res) => {
            localStorage.setItem("Token", res.data.token);
            localStorage.setItem("Role", res.data.user.role);
            localStorage.setItem("UserId", res.data.user.id);
            localStorage.setItem("SocketId", res.data.user.socketId);
            this.showLoader = false;
            this.$toasted.success("Erfolgreich eingeloggt", {
              className: ["toasting", "success"],
            });
            if (res.data.user.role === Role.SuperAdmin) {
              router.push({ name: "Admin" });
            } else if (res.data.user.role === Role.Advertiser) {
              router.push({
                path: `/admin/advertiser-reports/${res.data.user.id}`,
              });
            } else if (res.data.user.role === Role.Chatter) {
              router.push({ name: "Dashboard" });
            } else {
              router.push({ name: "Timeline" });
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toasted.error(
              "Etwas ist schiefgelaufen. Überprüfen Sie Ihre Anmeldeinformationen!.",
              {
                className: ["toasting", "error"],
              }
            );
            this.showLoader = false;
          });
      } else {
        this.$toasted.info(
          "Sie müssen Ihre E-Mail-Adresse und Ihr Passwort eingeben, um sich anzumelden!!!",
          {
            className: ["toasting", "infoToast"],
          }
        );
        setTimeout(() => {
          this.showLoader = false;
        }, 2000);
      }
      // }
    },
    closeModal() {
      this.$emit("closeLogin");
    },
    openForgotPassword() {
      this.openForgot = true;
      this.$emit("openForgot", this.openForgot);
    },
  },
});
