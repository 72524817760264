
import Vue from "vue";
import Modal from "@/components/Modal/Modal.vue";
import SaveNotes from "./SaveNotes.vue";
import UserService from "@/Services/user.service";

export default Vue.extend({
  props: {
    userID: Number,
  },
  components: {
    Modal,
    SaveNotes,
  },
  data() {
    return {
      userModal: false,
      sizeCard: {
        width: "600px",
        height: "850px",
      },
      user: {},
    };
  },
  methods: {
    openUserModal() {
      this.userModal = true;
    },
    closeUserModal() {
      this.userModal = false;
    },
    saveNotes() {
      this.$refs.Note.api();
      this.closeUserModal();
    },
    getUser() {
      UserService.getUser(this.$route.params.userId)
        .then((res) => {
          this.user = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goToUser() {
      this.$router.push(`/admin/client/${this.$route.params.userId}`);
    },
  },
  mounted() {
    this.getUser();
  },
});
