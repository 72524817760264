
import Vue from "vue";
import UserService from "@/Services/user.service";
import AuthService from "@/Services/auth.service";
import { Role } from "@/types/Role";
import DatePicker from "../../Inputs/BirthDate/BirthdateUnvalidated.vue";
import MessageService from "@/Services/message.service";

export default Vue.extend({
  components: {
    DatePicker,
  },
  data: () => ({
    dialogDelete: false,
    dback: true,
    headers: [
      { text: "Display Name", value: "displayName", width: "300px" },
      { text: "Email", value: "email", width: "300px" },
      { text: "Total Sent", value: "sent", width: "200px" },
      { text: "Total Received", value: "received", width: "200px" },
    ],
    usersList: [],
    selectedUser: null,
    messageReports: {},
    user: null,
    startDate: "",
    endDate: "",
    page: 2,
    loading: true,
    numberOfPages: 0,
    totalUsers: 0,
    options: {},
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.showModal();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getMessagesCount();
      },
      deep: true,
    },
  },
  mounted() {
    // this.$nextTick(async () => {
    //   await this.getReports();
    // });
    this.getMessagesCount();
  },

  methods: {
    getStartDateFromChild(date) {
      this.startDate = date;
    },
    getEndDateFromChild(date) {
      this.endDate = date;
    },
    // getUsers() {
    //   UserService.getAllAgentOrAdminByRole(Role.Chatter)
    //     .then((res) => {
    //       this.users = res.data.data;
    //       if (this.users.find(({ id }) => id == this.$route.params.userId)) {
    //         this.getReports();
    //       } else {
    //         this.$router.push({
    //           name: "Chatters",
    //         });
    //       }
    //     })
    //     .catch((err) => {});
    // },
    // getReports() {
    //   let idParam = this.$route.params.userId;
    //   if (!idParam) {
    //     AuthService.currentUser().then((res) => {
    //       this.getMessagesCount(res.data.id);
    //     });
    //   } else {
    //     this.getMessagesCount(idParam);
    //   }
    // },
    getMessagesCount() {
      let userId = this.$route.params.userId;
      const { page, itemsPerPage } = this.options;
      if (itemsPerPage == -1) {
        UserService.getAllMessageCount(userId)
          .then((res) => {
            console.log(res);
            this.messageReports.selectedChatter = res.data.selectedChatter;
            this.messageReports.sent = res.data.sent;
            this.messageReports.received = res.data.received;
            this.usersList = res.data.users.data;
          })
          .catch((err) => {});
      } else {
        UserService.getMessageCount(userId, page, itemsPerPage, "DESC")
          .then((res) => {
            this.messageReports.selectedChatter = res.data.selectedChatter;
            this.messageReports.sent = res.data.sent;
            this.messageReports.received = res.data.received;
            this.usersList = res.data.users;
            this.page = res.data.users.meta.page;
            this.totalUsers = res.data.users.meta.itemCount;
            this.numberOfPages = res.data.users.meta.pageCount;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    isUserSuperAdmin() {
      return this.$store.state.user.user.role === Role.SuperAdmin;
    },
    getCountMessagesFilteredWithDate() {
      const Id = this.$route.params.userId;
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      let pageNumber = page;

      MessageService.messagesCountForChatter(
        Id,
        pageNumber,
        itemsPerPage,
        "DESC",
        this.startDate,
        this.endDate
      )
        .then((res) => {
          this.messageReports.selectedChatter = res.data.selectedChatter;
          this.messageReports.sent = res.data.sent;
          this.messageReports.received = res.data.received;
          this.usersList = res.data.users;
          this.page = res.data.users.meta.page;
          // // this.totalUsers = res.data.users.meta.itemCount;
          this.numberOfPages = res.data.users.meta.pageCount;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goToUser(id: number) {
      this.$router.push({
        name: "ClientInfo",
        params: { id },
      });
    },
  },
});
