
import Vue from "vue";
import PayableSection from "./PayableSection/PayableSection.vue";
import PaySafe from "./PaySafe/PaySafeComponent.vue";
import BonusCode from "./BonusCode/BonusCode.vue";
import Navbar from "../../Navbar/NavbarComponent.vue";
import Undermenu from "../../Navbar/UnderMenu.vue";

export default Vue.extend({
  components: {
    PayableSection,
    PaySafe,
    BonusCode,
    Navbar,
    Undermenu,
  },
});
