
import Vue from "vue";
import Cards from "../Timeline/TimelineCards/Card.vue";
import Navbar from "../../Navbar/NavbarComponent.vue";
import Undermenu from "../../Navbar/UnderMenu.vue";
import IO from "socket.io-client";
let socket;
import { backendUrl, frontendUrl, socketUrl } from "@/helper/httpHelper";
import MessageService from "@/Services/message.service";
import MessagePagination from "./MessagePagination.vue";

export default Vue.extend({
  components: {
    Cards,
    Navbar,
    Undermenu,
    MessagePagination,
  },
  data() {
    return {
      users: null,
      currentPage: 1,
      totalPages: null,
      perPage: null,
      totalItems: null,
    };
  },
  async mounted() {
    socket = IO(socketUrl, {
      extraHeaders: {
        Authorization: `${localStorage.getItem("Token")}`,
      },
    });
    let socketId = localStorage.getItem("SocketId");
    socket.emit("markAsReadRecentNotificationsForUser", {
      socket_id: socketId,
    });
    socket.on(`markAsReadRecentNotificationsForUser/${socketId}`, () => {});
    await this.getAllChats();
  },
  watch: {
    currentPage: function () {
      this.getAllChats();
    },
    users: function (newVal, oldVal) {
      // console.log(val, newVal);
      this.users = newVal;
    },
  },
  methods: {
    getAllChats() {
      MessageService.getAllChats(this.currentPage, 10, "DESC")
        .then((res) => {
          this.users = res.data.data;
          this.totalPages = res.data.meta.pageCount;
          this.perPage = 10;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onPageChanged(page) {
      // console.log(page);
      this.currentPage = page;
      return this.currentPage;
    },
  },
});
