import { Http } from '../helper/httpHelper';
import { CoinPricing } from '@/types/coinPricing';

class CoinPricingService {
  getCoinPricings = (page?: number, take?: number, order: string = 'DESC') => Http.get(`/coin-pricing?page=${page}&take=${take}&order=${order}`);
  getAllCoinPricings = () => Http.get('/coin-pricing');

  getActiveCoinPricings = () => Http.get('/coin-pricing/active');

  deleteCoinPricing = (id: number) => Http.delete(`/coin-pricing/${id}`);

  updateCoinPricingActiveStatus = (id: number) => Http.put(`/coin-pricing/active/${id}`);

  createCoinPricing = (coinPricing: CoinPricing) => Http.post('/coin-pricing', coinPricing);

  updateCoinPricing = (coinPricing: CoinPricing) => {
    const enrichedCoinPricing = { ...coinPricing, id: null };
    delete enrichedCoinPricing['id'];

    return Http.put(`/coin-pricing/update/${coinPricing.id}`, enrichedCoinPricing);
  };
}

export default new CoinPricingService();
