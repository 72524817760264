
import Vue from "vue";
import PaymentService from "@/Services/payment.service";
import DatePicker from "../../Inputs/BirthDate/BirthdateUnvalidated.vue";

export default Vue.extend({
  components: {
    DatePicker,
  },
  data: () => ({
    dialogDelete: false,
    dback: true,
    searchPayments: "",
    headers: [
      { text: "Payment Date", value: "createdAt", width: "300px" },
      { text: "Payment Amount", value: "coinPricing.price", width: "300px" },
      { text: "Coin Amount", value: "coinPricing.coinAmount", width: "300px" },
      { text: "User name", value: "user.displayName", width: "300px" },
      { text: "Coin Amount", value: "coinPricing.coinAmount", width: "300px" },
      { text: "Type", value: "type", width: "300px" },
      { text: "Status", value: "status", width: "300px" },
    ],
    payments: null,
    totalUsers: 0,
    options: {},
    startDate: "",
    endDate: "",
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.showModal();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getPayments();
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getPayments();
    });
  },

  methods: {
    getStartDateFromChild(date) {
      this.startDate = date;
    },
    getEndDateFromChild(date) {
      this.endDate = date;
    },
    getPayments() {
      const { page, itemsPerPage } = this.options;
      if (itemsPerPage == -1) {
        const coinPricingId = this.$route.query.coinPricingId;
        PaymentService.getAllPayments(coinPricingId)
          .then((res) => {
            this.payments = res.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        PaymentService.getPayments(page, itemsPerPage, "DESC").then((res) => {
          this.payments = res.data.data;
        });
      }
    },
    getPaymentsFilterByDate() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      let pageNumber = page;

      if (this.startDate && this.endDate) {
        PaymentService.getPayments(
          pageNumber,
          itemsPerPage,
          "DESC",
          this.startDate,
          this.endDate,
          null
        )
          .then((res) => {
            this.payments = res.data.data;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onKeySearchPayments() {
      const { page, itemsPerPage } = this.options;
      if (itemsPerPage >= 0) {
        if (this.searchPayments !== "") {
          PaymentService.getPayments(
            page,
            itemsPerPage,
            "DESC",
            null,
            null,
            this.searchPayments
          )
            .then((res) => {
              this.payments = res.data.data;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    // formatDate(date) {
    //   return moment(date).format("M/DD/YYYY");
    // },
  },
});
