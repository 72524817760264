
import Vue from "vue";
import GiftService from "@/Services/gift.service";
import { backendUrl } from "@/helper/httpHelper";

export default Vue.extend({
  data() {
    return {
      gifts: {},
      gift: null,
      backendUrl: backendUrl,
    };
  },
  methods: {
    getGifts() {
      GiftService.getAllGifts()
        .then((res) => {
          this.gifts = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectGift(event) {
      this.gift = event;
      this.$emit("emitGift", this.gift);
    },
  },
  mounted() {
    this.getGifts();
  },
});
