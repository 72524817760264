
import Vue from "vue";
import AuthService from "@/Services/auth.service";

export default Vue.extend({
  data() {
    return {
      email: "",
      showLoader: false,
      spinner: {
        paddingLeft: "0em",
        height: "20px",
      },
    };
  },
  methods: {
    closeForgot() {
      this.$emit("closeForgot");
    },
    forgotPassword() {
      this.showLoader = true;
      if (this.email) {
        AuthService.forgotPassword(this.email)
          .then((res) => {
            console.log(res);
            this.$toasted.success("Check your email to set a new password", {
              className: ["toasted", "success"],
            });
            this.showLoader = false;
          })
          .catch((err) => {
            console.log(err);
            this.$toasted.error("Something happened, please try again!", {
              className: ["toasted", "error"],
            });
            this.showLoader = false;
          });
      } else {
        this.$toasted.info(
          "You must enter your email and password to login!!!",
          {
            className: ["toasting", "infoToast"],
          }
        );
        setTimeout(() => {
          this.showLoader = false;
        }, 2000);
      }
    },
  },
});
