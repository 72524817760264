
import Vue from 'vue';
import { VEmojiPicker, emojisDefault, categoriesDefault } from 'v-emoji-picker';

export default Vue.extend({
  components: {
    VEmojiPicker,
  },
  data() {
    return {
      valueInput: '',
      showDialog: false,
      test: null,
    };
  },
  methods: {
    toggleEmojiPicker() {
      this.showDialog = !this.showDialog;
    },
    onSelectEmoji(emoji) {
      this.valueInput = emoji.data;
      this.$emit('emitEmojis', this.valueInput);
      // Optional
      // this.toogleDialogEmoji();
    },
    clearEmojis() {
      this.valueInput = '';
    },
  },
});
