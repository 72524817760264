
import Vue from "vue";
import PresentCards from "./PresentCards/PresentCards.vue";
import Navbar from "../../Navbar/NavbarComponent.vue";
import Undermenu from "../../Navbar/UnderMenu.vue";

export default Vue.extend({
  components: {
    PresentCards,
    Navbar,
    Undermenu,
  },
});
