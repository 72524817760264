
import Vue from "vue";
import UserService from "@/Services/user.service";
import moment from "moment";
import AuthService from "@/Services/auth.service";
import { Role } from "@/types/Role";
import DatePicker from "../../Inputs/BirthDate/BirthdateUnvalidated.vue";

export default Vue.extend({
  components: {
    DatePicker,
  },
  data: () => ({
    dialogDelete: false,
    dback: true,
    headers: [
      { text: "DisplayName", value: "displayName", width: "300px" },
      // { text: "isOnline", value: "user.isOnline", width: "300px" },
      { text: "ZipCode", value: "user.zipCode", width: "300px" },
      { text: "Register date", value: "user.createdAt", width: "300px" },
      { text: "Verified", value: "user.isVerified", width: "300px" },
      { text: "Deleted Date", value: "user.deletedAt", width: "300px" },
      { text: "Payments", value: "payments", width: "200px" },
    ],
    references: [],
    selectedUser: null,
    startDate: "",
    endDate: "",
    page: 2,
    loading: true,
    numberOfPages: 0,
    totalUsers: 0,
    options: {},
    userId: null,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    totalRegisters() {
      if (this.references) {
        return this.references.length;
      }

      return 0;
    },
  },
  watch: {
    dialog(val) {
      val || this.showModal();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getUserReferences();
      },
      deep: true,
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.getReferences();
    // });
    this.getUserReferences();
  },

  methods: {
    affectDeletedRow(item) {
      if (item.user.deletedAt) {
        return "deleted--background";
      }
      return "normal--background";
    },
    isVerifiedString(isVerified: boolean) {
      if (isVerified) {
        return "Yes";
      }
      return "No";
    },
    getStartDateFromChild(date) {
      this.startDate = date;
    },
    getEndDateFromChild(date) {
      this.endDate = date;
    },
    // async getReferences() {
    //   this.userId = this.$route.params.userId;
    //   if (!this.userId) {
    //     await AuthService.currentUser().then((res) => {
    //       this.getUserReferences(res.data.id);
    //     });
    //   } else {
    //     this.getUserReferences(this.userId);
    //   }
    // },
    formatDate(date) {
      if (date) {
        return moment(date).format("M/DD/YYYY");
      }

      return "Not deleted";
    },
    getUserReferencesFilteredByDate() {
      let userId = this.$route.params.userId;
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      let pageNumber = page;

      UserService.getAdvertiserRefrencesFilterByDate(
        userId,
        // pageNumber,
        // itemsPerPage,
        null,
        null,
        "DESC",
        this.startDate,
        this.endDate
      )
        .then((res) => {
          this.references = res.data.references.data;
          this.selectedUser = res.data.selectedUser;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUserReferences() {
      const { page, itemsPerPage } = this.options;
      if (itemsPerPage == -1) {
        UserService.getAllAdvertiserRefrences(this.userId)
          .then((res) => {
            this.references = res.data.references.data;
            this.selectedUser = res.data.selectedUser;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.userId = this.$route.params.userId;
        UserService.getAdvertiserRefrences(
          this.userId,
          page,
          itemsPerPage,
          "DESC"
        )
          .then((res) => {
            this.references = res.data.references.data;
            this.selectedUser = res.data.selectedUser;
            this.page = res.data.references.meta.page;
            this.totalUsers = res.data.references.meta.itemCount;
            this.numberOfPages = res.data.references.meta.pageCount;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // getTotalPayments(user) {
    //   if (user?.payments > 0) {
    //     return "Yes";
    //   } else {
    //     return "No";
    //   }
    // },
    isUserSuperAdmin() {
      return this.$store.state.user.user.role === Role.SuperAdmin;
    },
    openNewTab(link: string) {
      window.open(link);
    },
  },
});
