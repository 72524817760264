
import Vue from "vue";
import ClientInfoNav from "./ClientInfoNav.vue";
import RightInfo from "./RightInfo.vue";
import UserService from "@/Services/user.service";

export default Vue.extend({
  components: {
    ClientInfoNav,
    RightInfo,
  },
  data() {
    return {
      user: null,
    };
  },
  methods: {
    getUser() {
      UserService.getUser(this.$route.params.id)
        .then((res) => {
          this.user = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    gender() {
      if (this.user.gender === 0) {
        this.user.gender = "Male";
      } else {
        this.user.gender = "Female";
      }
      return this.user.gender;
    },
  },
  mounted() {
    this.getUser();
  },
});
