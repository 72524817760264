
import PaymentService from "@/Services/payment.service";
export default {
  name: 'PayPalButton',
  props: {  
    amount: {
      type: Number,
      required: true
    },
    coinId: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      showSuccess: false, // Controls display of success message
    };
  },
  mounted() {
    if(!paypal) {
      return;
    }
    paypal.Buttons({
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: this.amount.toFixed(2)
            }
          }]
        });
      },
      onApprove: (data, actions) => {
        const userId = localStorage.getItem("UserId")
        return actions.order.capture().then(details => {
          console.log("details -> ", details)
          PaymentService.buyCoinsPaymentUpdate(userId, this.coinId).then((res) => {
            console.log("done api success");

            // Show success message and hide PayPal button
            this.showSuccess = true;

            // Display success toast
            this.$toasted.success("Munzen erfolgreich hinzugefugt..", {
              className: ["toasting", "success"],
            });

            // Redirect to home page after 3 seconds
            setTimeout(() => {
              this.$router.push('/timeline').then(() => {
                window.location.reload();
              });
            }, 3000);
          })
          .catch((err) => {
            this.$toasted.error('Die Zahlung war nicht erfolgreich. Bitte versuchen Sie es erneut.', {
           className: ['toasting', 'error'],
         });
          });
        });
      },
      onError: (err) => {
        console.error('PayPal Checkout error:', err);
      }
    }).render('#paypal-button-container');
  }
}
