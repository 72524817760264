import { Http } from '../helper/httpHelper';

class PaymentService {
  getAllPayments = (coinPricingId: number) => Http.get(`/payment?coinPricingId=${coinPricingId ?? ''}`);
 
  getPayments = (page: number, take: number, order: string = "DESC", startDate?: string, secondDate?: string, query?: string) =>
    Http.get(`/payment?order=${order}&page=${page}&take=${take}${startDate != null ? `&firstDate=${startDate}` : ''}${secondDate != null ? `&secondDate=${secondDate}` : ''}${query != null ? `&query=${query}` : ''}`);

  getUserPayments = (userId: number, page: number, take: number, order: string = "DESC") =>
    Http.get(`/payment/user/${userId}`);

  buyCoinsPaymentUpdate = (userId: string, productId:string, paymentType: string = "Paypal", ) =>
    Http.get(`/payment/order?userId=${userId}&type=${paymentType}&productId=${productId}`);
}

export default new PaymentService();
