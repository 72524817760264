
import Vue from "vue";
import Birthdate from "@/components/Dashboard/Inputs/BirthDate/Birthdate.vue";
import UserService from "@/Services/user.service";
import { createAgentDto } from "@/types/createAgentDto";
import { Role } from "@/types/Role";

export default Vue.extend({
  components: {
    Birthdate,
  },
  props: {},
  data() {
    return {
      agent: {
        displayName: "",
        email: "",
        birthDate: "",
        password: "",
        role: Role,
      } as unknown as createAgentDto,
    };
  },
  methods: {
    getDateFromChild(date) {
      this.agent.birthDate = date;
    },
    createAgent() {
      const newAgent = this.agent;
      newAgent.role = Role[this.agent.role];
      UserService.registerAgent(newAgent)
        .then((res) => {
          this.$toasted.success("Agent created succsesfully", {
            className: ["toasted", "success"],
          });
        })
        .catch((err) => {
          if (err.response && err.response.data.statusCode === 400) {
            this.$toasted.error(`${err.response.data.message}`, {
              className: ["toasted", "error"],
            });
            return;
          }
          this.$toasted.error("Request has failed. Try again", {
            className: ["toasted", "error"],
          });
        });
    },
    clearFields() {
      this.agent = {
        fistName: "",
        lastName: "",
        birthDate: "",
        password: "",
        email: "",
        role: "",
      };
    },
  },
});
