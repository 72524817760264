
import Vue from 'vue';
import UserService from '@/Services/user.service';
import MassSpamService from '@/Services/massSpam.service';

export default Vue.extend({
  components: {},
  props: {},
  data() {
    return {
      massSpam: {
        ageFrom: 0,
        ageTo: 0,
        message: "",
        lastSentMessage: null,
        excludedUsers: [],
        selectedProfile: null,
        hasProfilePicture: false,
        hasCoins: false,
        isOnline: false,
        noCoins: false,
        noProfilePicture: false,
        notOnline: false,
      },
      placeHolderUsers: [],
      isLoadingUsers: false,
      isProfileLoading: false,
      activePicker: null,
      profiles: null,
      users: null,
      userSearchInput: '',
      selectedProfile: null,
      profileSearchInput: '',
      totalUsers: 0,
    };
  },
  computed: {
    usersComputed() {
      return this.users;
    },
    profilesComputed() {
      return this.profiles;
    },
    totalUsersComputed(){
      return this.totalUsers;
    }
  },
  watch: {
    massSpam: {
      async handler(val){
        let localTotalUsers = 0;
        await MassSpamService.getMassSpamsUserCount(val).then(res => {
          localTotalUsers = res.data;
        })

        this.totalUsers = localTotalUsers;
      },
      deep: true
    },
    userSearchInput(value) {
      this.getUsersByName(value);
    },
    profileSearchInput(value) {
      this.getProfilesByName(value);
    }
  },
  methods: {
    async createMassSpam() {
      return MassSpamService.createMassSpam(this.massSpam);
    },
    async getUsersByName(value: string) {
      this.isLoadingUsers = true;
      UserService.getUsersWithoutDeleted(value).then((res) => {
        this.users = res.data;
      }).finally(() => this.isLoadingUsers = false);
    },
    async getProfilesByName(value: string) {
      this.isProfileLoading = true;
      UserService.getProfilesWithoutDeleted(value).then((res) => {
        this.profiles = res.data;
        this.isProfileLoading = false;
      }).finally(() => this.isProfileLoading = false);
      ;
    },
    onUserSelect(selected) {
      this.placeHolderUsers = [];
      this.massSpam.excludedUsers.push(selected);
    },
    removeFromExcludedUsers(userIndex) {
      this.massSpam.excludedUsers.splice(userIndex, 1);
    },
    clearFields() {
      this.massSpam = {
        ageFrom: 0,
        ageTo: 0,
        message: "",
        lastSentMessage: null,
        excludedUsers: [],
        selectedProfile: null,
        hasProfilePicture: false,
        hasCoins: false,
        isOnline: false,
        noCoins: false,
        noProfilePicture: false,
        notOnline: false,
      }
    }
  },
});
