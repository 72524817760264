
import Vue from 'vue';
import CreateProfile from './Admins/CreateProfile.vue';
import GalleryPhotos from './Admins/GalleryPhotos.vue';

export default Vue.extend({
  components: {
    CreateProfile,
    GalleryPhotos,
  },
  data() {
    return {
      length: 2,
      onboarding: 0,
      item: [

      ],
    };
  },
  methods: {
    next() {
      this.onboarding = this.onboarding + 1 === this.length
        ? 0
        : this.onboarding + 1;
    },
    prev() {
      this.onboarding = this.onboarding - 1 < 0
        ? this.length - 1
        : this.onboarding - 1;
    },
    submitProfile() {
      this.$refs.galleryChild.sendImages().then(()=>{
        this.$refs.myChild.createProfile();
        this.$refs.myChild.onClickClear();
      });
    },
  },
});
