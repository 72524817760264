
import Vue from 'vue';

const CENTER_X = 20;
const CENTER_Y = 20;
const RADIUS = 12;

function positionOnCircle(radius, angle, center) {
  return {
    x: center.x + (radius * Math.cos(toRadians(angle))),
    y: center.y + (radius * Math.sin(toRadians(angle))),
  };
}

function toRadians(angle) {
  return (angle * Math.PI) / 180;
}

function calculatePositions(component) {
  const angleIncrement = 360 / component.circles.length;
  const positions = {};
  component.circles.forEach((circle, index) => {
    positions[index] = positionOnCircle(
      RADIUS,
      angleIncrement * index,
      { x: CENTER_X, y: CENTER_Y },
    );
  });

  return positions;
}

export interface Circle{
  color: string;
  radius: number;
}

export default Vue.extend({
  props: {
    spinnerPadding: Object,
  },
  data() {
    return {
      circles: [
        { color: '#717cbd', radius: 0 },
        { color: '#717cbd', radius: 0 },
        { color: '#717cbd', radius: 0 },
        { color: '#8277bc', radius: 0 },
        { color: '#8277bc', radius: 0 },
        { color: '#8277bc', radius: 0 },
        { color: '#a01dcd', radius: 0 },
        { color: '#a01dcd', radius: 0 },
        { color: '#a01dcd', radius: 0 },
      ] as Circle[],
      counter: 0,
      interval: null,
    };
  },
  computed: {
    circlePositions() {
      const angleIncrement = 360 / this.circles.length;
      const positions = {};
      this.circles.forEach((circle, index) => {
        positions[index] = positionOnCircle(
          RADIUS,
          angleIncrement * index,
          { x: CENTER_X, y: CENTER_Y },
        );
      });

      return positions;
    },
  },
  created(): void {
    this.counter = 100;
    this.interval = setInterval(() => {
      const currentCount = this.counter + 1;
      this.counter = currentCount;
      const mappedCircles = this.circles.map((item: Circle, index: number) => ({
        ...item,
        radius: (currentCount + index) % 8,
      }));
      this.circles = mappedCircles;
    }, 70);
  },
  destroyed() {
    const currentInterval = this.interval;
    clearInterval(currentInterval);
  },
});
