
import Vue from "vue";
import Navbar from "./NavbarComponent.vue";
import UnderMenu from "./UnderMenu.vue";
import Card from "@/components/Dashboard/Pages/Timeline/TimelineCards/Card.vue";
import UserService from "@/Services/user.service";
import FilterSearch from "./FilterSearch.vue";

export default Vue.extend({
  components: {
    Navbar,
    UnderMenu,
    Card,
    FilterSearch,
  },
  data() {
    return {
      profiles: {},
      items: null,
      select: null,
      search: null,
      loading: false,
      onClickFilter: false,
      gender: null,
      age: null,
    };
  },
  methods: {
    toggleFilter() {
      this.onClickFilter = !this.onClickFilter;
    },
    searchOptions(payload) {
      this.gender = payload.gender;
      this.age = payload.age;
      if (this.gender && this.age) {
        this.searchForProfiles();
      }
    },
    randomProfiles() {
      UserService.generateRandomProfiles()
        .then((res) => {
          this.profiles = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchForProfiles(query) {
      this.loading = true;
      if (query) {
        UserService.searchForProfiles(query, this.gender, this.age)
          .then((res) => {
            this.items = res.data;
          })
          .catch((err) => console.log(err));
        setTimeout(() => {
          this.loading = false;
        }, 500);
      } else {
        UserService.searchForProfiles("", this.gender, this.age)
          .then((res) => {
            this.items = res.data;
          })
          .catch((err) => console.log(err));
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
  },
  watch: {
    search(val) {
      val &&
        val !== this.select &&
        val?.length === 3 &&
        this.searchForProfiles(val);
    },
    select() {
      this.$router.push({
        name: "Profile",
        params: { id: this.select.id },
        replace: true,
      });
      location.reload();
    },
  },
  async mounted() {
    await this.randomProfiles();
  },
});
