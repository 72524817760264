
import Vue from "vue";
import { Coupon } from "@/types/coupon";
import { CoinPricing } from "@/types/coinPricing";
import CouponService from "@/Services/coupon.service";

export default Vue.extend({
  props: {
    avalaibleMethods: Object,
  },
  data() {
    return {
      coinPrice: {
        id: 0,
        name: "",
        price: 0,
        coinAmount: 0,
        isActive: null,
        hasPaypal: false,
        hasSofort: false,
        hasCall2Pay: false,
        hasMobilePay: false,
        hasPayDirekt: false,
        hasMaestroCard: false,
        hasVisa: false,
        hasMasterCard: false,
        hasKlarna: false,
        hasPaySafeCard: false,
      } as CoinPricing,
      // coinPrice: {} as CoinPricing,
      priceMethod: {} as CoinPricing,
      coupon: {} as Coupon,
      couponCode: "",
    };
  },
  methods: {
    redirectToPayment(paymentUrl: string) {
      window.location.href = paymentUrl;
    },
    submitCoinPricing() {
      const newCoinPrice = this.coinPrice;
    },
    verifyCoupon() {
      CouponService.verifyCoupon(this.couponCode, <number>this.priceMethod.id)
        .then((res) => {
          this.coinPrice.price = this.priceMethod.price - res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.priceMethod = this.avalaibleMethods;
    this.coinPrice.price = this.priceMethod.price;
  },
});
