import axios from 'axios';
import { VBreadcrumbsDivider } from 'vuetify/lib';

// eslint-disable-next-line import/prefer-default-export
// local creds
// export const backendUrl = 'http://localhost:3000/';
// export const frontendUrl = 'http://localhost:8080/';
// export const socketUrl = 'localhost:3000/';


// live creds

export const frontendUrl = 'https://flirtenjoy.de/';
export const backendUrl = 'https://back.flirtenjoy.de/';
export const socketUrl = 'https://back.flirtenjoy.de/'; 


export const Http = axios.create({
  baseURL: backendUrl,
});

Http.interceptors.request.use((config) => {
  if (config && config.headers) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${localStorage.getItem('Token')}`;
  }
  return config;
});

