import { Http } from '../helper/httpHelper';
import { Gift } from '@/types/gift';

class GiftService {
  getGifts = (page?: number, take?: number, order: string = 'DESC') => Http.get(`/gift?page=${page}&take=${take}&order=${order}`);
  getAllGifts = () => Http.get('/gift');

  updateGift = (gift: Gift) => {
    const editGift = {
      name: gift.name,
      coinPrice: gift.coinPrice,
      isActive: gift.isActive,
    } as Gift;

    if (!gift.giftImage.id) {
      editGift.giftImage = gift.giftImage;
    }

    const formData = new FormData();
    Object.entries(editGift).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return Http.put(`/gift/update/${gift.id}`, formData);
  }

  deleteGift = (id: number) => Http.delete(`/gift/${id}`);

  updateGiftActiveStatus = (id: number) => Http.put(`/gift/active/${id}`);

  createGift = (gift: Gift) => {
    const formData = new FormData();
    Object.entries(gift).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return Http.post('/gift', formData);
  };

  giftsUserReceived = (id: number, page: number, take: number, order: string = "DESC") => Http.get(`/gift/user/${id}/recived?page${page}&take=${take}&order=${order}`);
  giftsAllUserReceived = (id: number) => Http.get(`/gift/user/${id}/recived`);

  giftsUserSent = (id: number) => Http.get(`/gift/user/${id}/sent`);
}

export default new GiftService();
