
import Vue from "vue";
import UserService from "@/Services/user.service";
import Navbar from "../../Navbar/NavbarComponent.vue";
import Undermenu from "../../Navbar/UnderMenu.vue";
import UserProfileCard from "./UserProfileCard/UserProfileCard.vue";
import UserDetails from "./UserDetails/UserDetails.vue";
import UserPics from "./UserPics/UserPics.vue";
import ProfileGifts from "./ProfileGifts/ProfileGifts.vue";

export default Vue.extend({
  props: {
    name: String,
  },
  data() {
    return {
      user: null,
      id: null,
      isFriend: false,
    };
  },
  components: {
    Navbar,
    Undermenu,
    UserProfileCard,
    UserDetails,
    UserPics,
    ProfileGifts,
  },
  methods: {
    getUser() {
      UserService.getUser(this.$route.params.id)
        .then((res) => {
          this.user = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  async mounted() {
    await this.getUser();
  },
});
