
import Vue from "vue";
import AuthService from "@/Services/auth.service";
import Header from "./Header.vue";
import router from "../../../router/index";
import { Role } from "@/types/Role";

export default Vue.extend({
  components: {
    Header,
  },
  data() {
    return {
      user: null,
    };
  },
  computed: {
    getVerificationCode(): string {
      return `${this.$route.params.verificatonCode}`;
    },
  },
  methods: {
    verifyAccount() {
      AuthService.verifyUser(this.getVerificationCode)
        .then((res) => {
          if (res.data.token) {
            localStorage.setItem("Token", res.data.token);
            localStorage.setItem("Role", res.data.user.role);
            localStorage.setItem("UserId", res.data.user.id);
            localStorage.setItem("SocketId", res.data.user.socketId);
            this.$toasted.success("Ihr Konto ist verifiziert.", {
              className: ["toasting", "success"],
            });
            if (res.data.user.role === Role.SuperAdmin) {
              router.push({ name: "Admin" });
            } else if (res.data.user.role === Role.Advertiser) {
              router.push({ name: "AdvertiserReports" });
            } else if (res.data.user.role === Role.Chatter) {
              router.push({ name: "Waiting" });
            } else {
              router.push({ name: "Timeline" });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.verifyAccount();
  },
});
