
import Vue from "vue";
import { validationMixin } from "vuelidate";
import AuthService from "@/Services/auth.service";

export default Vue.extend({
  data() {
    return {
      user: {
        displayName: "",
        email: "",
        password: "",
        // confirmPassword: '',
      },
    };
  },
  mixins: [validationMixin],
  computed: {
    getUserID() {
      const Id = this.getCurrentUser();
    },
  },
  mounted() {
    this.getCurrentUser();
  },
  methods: {
    getCurrentUser() {
      AuthService.currentUser()
        .then((res) => {
          this.user = res.data;
          console.log(this.user);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clear() {
      this.$v.$reset();
      this.user.name = "";
      this.user.email = "";
      this.user.password = "";
      this.user.confirmPassword = "";
    },
    editUser() {
      let editUser = this.user;

      if (this.user.displayName || this.user.email) {
        AuthService.updateUserProfile(this.user)
          .then((res) => {
            this.$toasted.success("Your account is edited successfully", {
              className: ["toasting", "success"],
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$toasted.info("You should edit at least one of the field!", {
          className: ["toasting", "infoToast"],
        });
      }
    },
  },
});
