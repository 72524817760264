
import Vue from "vue";
import MessageService from "@/Services/message.service";
import { backendUrl, socketUrl } from "@/helper/httpHelper";
import socketIO from "socket.io-client";
let socket;

export default Vue.extend({
  data() {
    return {
      headers: [
        { text: "Bild", value: "bild", width: "200px" },
        { text: "Kunden", value: "user.displayName", width: "200px" },
        { text: "IN", value: "in", width: "200px" },
        { text: "Last Action", value: "lastAction", width: "200px" },
        { text: "Out", value: "out", width: "200px" },
        { text: "Profile", value: "profile.displayName", width: "200px" },
        { text: "Profile Image", value: "profileImage", width: "200px" },
        { text: "Action", value: "actions", width: "100px" },
      ],
      users: [],
      backendUrl: backendUrl,
      user: null,
      page: 2,
      loading: true,
      numberOfPages: 0,
      totalUsers: 0,
      options: {},
    };
  },
  watch: {
    options: {
      handler() {
        this.getAsa();
      },
      deep: true,
    },
  },
  methods: {
    takeChat(chatId: number) {
      socket.emit("takeChat", { chat_id: chatId });
      this.$toasted.success("You succesfully have taken this chat", {
        className: ["toasted", "success"],
      });
      this.getAsa();
    },
    getAsa() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      const socketId = localStorage.getItem("SocketId");
      if (itemsPerPage == -1) {
        socket.emit("chat/active/for24h", { socket_id: socketId });
        socket.on(`chat/${socketId}/active/for24/response`, (result) => {
          if (result.chats?.data?.length >= 0) {
            this.users = result.chats.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        });
      } else {
        socket.emit("chat/active/for24h", {
          socket_id: socketId,
          page: page,
          order: "DESC",
          take: itemsPerPage,
        });
        socket.on(`chat/${socketId}/active/for24/response`, (result) => {
          if (result.chats?.data?.length >= 0) {
            this.users = result.chats.data;
            this.page = result.chats.meta.page;
            this.totalUsers = result.chats.meta.itemCount;
            this.numberOfPages = result.chats.meta.pageCount;
            this.loading = false;
          } else {
            this.loading = false;
          }
        });
      }
    },
  },
  async mounted() {
    const socketId = localStorage.getItem("SocketId");
    socket = socketIO(socketUrl, {
      extraHeaders: {
        Authorization: `${localStorage.getItem("Token")}`,
      },
    });

    socket.on("chat/message/new", (res) => {
      if (res.message?.chatId) {
        const chat = this.users.find((ch) => ch.id == res.message.chatId);
        if (chat) {
          this.getAsa();
        }
      }
    });
    socket.on("logoutResponse", (res) => {
      this.getAsa();
    });
  },
});
