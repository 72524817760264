
import Vue from "vue";
import MessageService from "@/Services/message.service";
import socketIO from "socket.io-client";
import { backendUrl, frontendUrl, socketUrl } from "@/helper/httpHelper";

let socket;

export default Vue.extend({
  data() {
    return {
      headers: [
        { text: "Image", value: "image", width: "200px" },
        { text: "Client", value: "user.displayName", width: "200px" },
        {
          text: "Message",
          value: "lastMessage.message",
          width: "900px",
        },
        { text: "Profile", value: "profile.displayName", width: "200px" },
        { text: "Image", value: "profileImage", width: "200px" },
        { text: "Actions", value: "actions", width: "100px" },
      ],
      users: [],
      messages: [],
      backendUrl: backendUrl,
      page: 2,
      loading: true,
      numberOfPages: 0,
      totalUsers: 0,
      options: {},
    };
  },
  watch: {
    options: {
      handler() {
        this.getWaitingChat();
      },
      deep: true,
    },
  },
  methods: {
    addBoldFont(user) {
      if (!user.lastMessage?.seenChatter && user.lastMessage?.sendType == 0) {
        return "font-bold";
      }

      return "font-normal";
    },
    takeChat(chatId: number) {
      socket.emit("takeChat", { chat_id: chatId });

      socket.on("chatTaken", (res) => {
        if (res.result) {
          // window.location.replace("#/admin/activeDialog");
          this.$toasted.success("You succesfully have taken this chat", {
            className: ["toasted", "success"],
          });
          const { page, itemsPerPage } = this.options;
          socket.emit("chat/waiting", {
            page: page,
            order: "DESC",
            take: itemsPerPage,
          });
        } else {
          this.$toasted.error("This chat is taken try another one", {
            className: ["toasted", "error"],
          });
        }
      });
    },
    getWaitingChat() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      if (itemsPerPage == -1) {
        socket.emit("chat/waiting");
        socket.on("chat/waiting/true", (result) => {
          if (result?.result == true) {
            socket.emit("chat/waiting");
          }
        });
        socket.on("chat/waiting/response", (result) => {
          if (result.chats?.data.length >= 0) {
            this.users = result.chats.data;
            this.loading = false;
          }
        });
      } else {
        socket.emit("chat/waiting", {
          page: page,
          order: "DESC",
          take: itemsPerPage,
        });
        socket.on("chat/waiting/true", (result) => {
          if (result?.result == true) {
            socket.emit("chat/waiting", {
              page: page,
              order: "DESC",
              take: itemsPerPage,
            });
          }
        });
        socket.on("chat/waiting/response", (result) => {
          if (result.chats?.data.length >= 0) {
            this.users = result.chats.data;
            this.page = result.chats.meta.page;
            this.totalUsers = result.chats.meta.itemCount;
            this.numberOfPages = result.chats.meta.pageCount;
            this.loading = false;
          }
        });
      }
    },
  },
  created() {
    socket = socketIO(socketUrl, {
      extraHeaders: {
        Authorization: `${localStorage.getItem("Token")}`,
      },
    });
    // this.getWaitingChat();
    socket.on("message", (message) => {
      this.takeChat();
    });
    socket.on("chat/message/new", (res) => {
      if (res.message?.chatId) {
        const chat = this.users.find((ch) => ch.id == res.message.chatId);
        if (chat) {
          this.users = this.users.filter((ch) => ch.id != chat.id);
          chat["lastMessage"] = res.message;
          this.users.unshift(chat);
          this.users.forEach((user) => {
            if (user.lastMessage?.bold) {
              this.addBoldFont(user);
              // this.users = user;
            }
          });
        }
      }
    });
    socket.on("logoutResponse", (res) => {
      if (res.result) {
        const { page, itemsPerPage } = this.options;
        socket.emit("chat/waiting", {
          page: page,
          order: "DESC",
          take: itemsPerPage,
        });
      }
    });
  },
});
