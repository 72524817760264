import { ActionTree } from 'vuex';
import { RootState } from '../types';
import authService from '@/Services/auth.service';
import userService from '@/Services/user.service';
import { UserState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<UserState, RootState> = {
  async setProfile({ commit }, profile, gallery?): Promise<void> {
    profile.gallery = gallery;
    const response = await userService.createProfile(profile);
    commit('CREATE_PROFILE', response.data);
  },
  async currentUser({ commit }): Promise<any> {
    const response = await authService.currentUser();
    commit('STORE_USER', response.data);
    return response.data
  },
};
