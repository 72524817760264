
import Vue from "vue";
import axios from "axios";
import UserService from "@/Services/user.service";
import AuthService from "@/Services/auth.service";
import router from "../../../router/index";
import { Role } from "@/types/Role";
import { RegisterUser } from "../../../types/registerUser";
import { Gender } from "../../../types/Gender";
import BirthdateInput from "../../Dashboard/Inputs/BirthDate/Birthdate.vue";
import ChooseGender from "./ChooseGender.vue";
import LookingFor from "./LookingFor.vue";

export default Vue.extend({
  components: {
    BirthdateInput,
    ChooseGender,
    LookingFor,
  },
  data() {
    return {
      user: {
        displayName: "",
        password: "",
        email: "",
        birthDate: "",
        country: "",
        address: "",
        zipCode: "",
        gender: Gender.Male,
        lookingFor: Gender.Female,
        height: null,
        hairColor: "",
        eyeColor: "",
        figure: "",
        smoker: "",
        seeking: "",
        relationShipStatus: "",
        children: "",
        job: "",
        language: "",
        flirtText: "",
      } as unknown as RegisterUser,
      userCreated: null,
      coinPricings: [],
      checked: false,
      checkedData: false,
      erorrWithoutCheck: "",
      closeM: false,
      userName: "",
      showLoader: false,
      Gender,
      male: 0,
      female: 1,
      emailTakenErrorMessage: "",
      buttons: [
        {
          isActive: false,
          gender: 0,
          name: "Mann",
          iconName: "fas fa-mars",
        },
        {
          isActive: false,
          gender: 1,
          name: "Frau",
          iconName: "fas fa-venus",
        },
      ],
      lookingForButtons: [
        {
          isActive: false,
          lookingFor: 0,
          name: "Mann",
          iconName: "fas fa-mars",
        },
        {
          isActive: false,
          lookingFor: 1,
          name: "Frau",
          iconName: "fas fa-venus",
        },
      ],
      spinner: {
        paddingLeft: "3em",
        paddingBottom: "4em",
      },
    };
  },
  methods: {
    activateButton(activatedButton) {
      // eslint-disable-next-line no-restricted-syntax
      for (const button of this.buttons) {
        button.isActive = button === activatedButton;
      }
      this.user.gender = activatedButton.gender;
    },
    activateLookingButton(activatedButton) {
      // eslint-disable-next-line no-restricted-syntax
      for (const button of this.lookingForButtons) {
        button.isActive = button === activatedButton;
      }
      this.user.lookingFor = activatedButton.lookingFor;
    },
    addUser() {
      this.emailTakenErrorMessage = "";
      this.showLoader = true;
      const data = {
        displayName: this.user.displayName,
        email: this.user.email,
        password: this.user.password,
        birthDate: this.user.birthDate,
        country: this.user.country,
        address: this.user.address,
        zipCode: this.user.zipCode,
        gender: this.user.gender,
        lookingFor: this.user.lookingFor,
        height: this.user.height,
        hairColor: this.user.hairColour,
        eyeColor: this.user.eyeColour,
        figure: this.user.figure,
        smoker: this.user.smoker,
        seeking: this.user.seeking,
        relationShipStatus: this.user.relationShipStatus,
        children: this.user.children,
        job: this.user.job,
        language: this.user.language,
        flirtText: this.user.flirtText,
      };
      data.displayName = data.displayName.replace(/[@\s]/g, "");
      if (this.checked && this.checkedData) {
        UserService.registerUser(data, this.getReferenceId)
          .then((res) => {
            this.userCreated = res.data;
            this.showLoader = false;
            this.$toasted.success(
              "Registrierung Abgeschlossen. Bitte überprüfen sie ihre email.",
              {
                className: ["toasting", "success"],
              }
            );
            

            // LOGIN AUTOMATICALLY WHEN signup
            const loginData = {email: this.user.email,
        password: this.user.password,};
            AuthService.auth(loginData)
          .then((resp) => {
           if(resp) {
             console.log("  resp.data.user -> ", resp.data.user);
            localStorage.setItem("Token", resp.data.token);
            localStorage.setItem("Role", resp.data.user.role);
            localStorage.setItem("UserId", resp.data.user.id);
            localStorage.setItem("SocketId", resp.data.user.socketId);
            this.showLoader = false;
            this.$toasted.success("Erfolgreich eingeloggt", {
              className: ["toasting", "success"],
            });
            if (resp.data.user.role === Role.SuperAdmin) {
              router.push({ name: "Admin" });
            } else if (resp.data.user.role === Role.Advertiser) {
              router.push({
                path: `/admin/advertiser-reports/${resp.data.user.id}`,
              });
            } else if (resp.data.user.role === Role.Chatter) {
              router.push({ name: "Dashboard" });
            } else {
              router.push({ name: "Timeline" });
            }
            this.closeModal();
           }
          })
          .catch((err) => {
            console.log(err);
            this.$toasted.error(
              "test Etwas ist schiefgelaufen. Überprüfen Sie Ihre Anmeldeinformationen!.",
              {
                className: ["toasting", "error"],
              }
            );
            this.showLoader = false;
          });
          })
          .catch((err) => {
            if (err.response.data && err.response.status) {
              this.emailTakenErrorMessage = err.response.data.message;
              this.showLoader = false;
            } else {
              this.$toasted.error("Something happend. Try again!", {
                className: ["toasting", "error"],
              });
              this.showLoader = false;
            }
            // console.log("something happened ", err);
          });
      } else {
        this.erorrWithoutCheck =
          "Sie müssen den Geschäftsbedingungen zustimmen";
        setTimeout(() => {
          this.showLoader = false;
        }, 2000);
      }
    },
    getDateFromChild(value) {
      this.user.birthDate = value;
    },
    closeModal() {
      this.$emit("closeRegister");
    },
  },
  computed: {
    getReferenceId(): string {
      return this.$route.params.referenceId;
    },
  },
});
