
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      placeholder: "Get me in",
      closeModal: false,
    };
  },
  methods: {
    closeModals() {
      this.closeModal = false;
      this.$emit("closeModals", this.closeModal);
    },
  },
});
