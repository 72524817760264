var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{attrs:{"justify":"center"}},[_vm._l((Object.keys(_vm.$slots)),function(slotName){return _c('template',{staticClass:"content",slot:slotName},[_vm._t(slotName)],2)}),_c('v-card-text',[_c('v-container',{staticClass:"scroll-container"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"4"}},[_c('v-text-field',{attrs:{"label":"First Name*","required":""},model:{value:(_vm.agent.displayName),callback:function ($$v) {_vm.$set(_vm.agent, "displayName", $$v)},expression:"agent.displayName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"4"}},[_c('Birthdate',{attrs:{"labelName":`Birth Day`},on:{"passDate":_vm.getDateFromChild,"change":_vm.agent.birthDate}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"label":"Password*","type":"password"},model:{value:(_vm.agent.password),callback:function ($$v) {_vm.$set(_vm.agent, "password", $$v)},expression:"agent.password"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"label":"Email*"},model:{value:(_vm.agent.email),callback:function ($$v) {_vm.$set(_vm.agent, "email", $$v)},expression:"agent.email"}})],1),_c('v-col',{staticClass:"padding-top--select",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-select',{attrs:{"items":[
              'SuperAdmin',
              'Admin',
              'Manager',
              'Advertiser',
              'Chatter',
            ],"label":"Role*"},model:{value:(_vm.agent.role),callback:function ($$v) {_vm.$set(_vm.agent, "role", $$v)},expression:"agent.role"}})],1)],1)],1),_c('small',{staticClass:"required-small"},[_vm._v("*indicates required field")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }