
import Vue from "vue";
import ClientInfoNav from "./ClientInfoNav.vue";
import RightInfo from "./RightInfo.vue";
import UserService from "@/Services/user.service";
import { backendUrl } from "@/helper/httpHelper";

export default Vue.extend({
  components: {
    ClientInfoNav,
    RightInfo,
  },
  data() {
    return {
      user: null,
      backendUrl: backendUrl,
    };
  },
  methods: {
    getUser() {
      UserService.getUser(this.$route.params.id)
        .then((res) => {
          this.user = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getUser();
  },
});
