import { Http } from '../helper/httpHelper';
import { Coupon } from '@/types/coupon';

class CouponService {
  getCoupons = () => Http.get('/coupon');

  createCoupon = (gift: Coupon) => {
    const formData = new FormData();
    Object.entries(gift).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return Http.post('/gift', formData);
  };

  updateCouponActiveStatus = (id: number) => Http.put(`/coupon/active/${id}`)

  verifyCoupon = (couponCode: string, coinPriceId: number) => Http.get(`/coupon/verify/${couponCode}/${coinPriceId}`)
}

export default new CouponService();
