
import Vue from "vue";
import MessageService from "@/Services/message.service";

export default Vue.extend({
  data() {
    return {
      statistics: null,
      clientStatistics: null,
      loading: false,
      userId: null,
    };
  },
  methods: {
    getChatterStatistics() {
      MessageService.getDashboardData(
        this.currentUser?.id ?? localStorage.getItem("UserId")
      )
        .then((res) => {
          this.statistics = res.data;
          this.loading = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getClientStats() {
      MessageService.getDashboardClientStatsData()
        .then((res) => {
          this.clientStatistics = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.user.user;
    },
  },
  watch: {
    currentUser: {
      handler(newVal) {
        if (newVal) {
          this.getChatterStatistics();
        }
      },
      immediate: true,
    },
  },
  async created() {
    await this.getChatterStatistics();
    await this.getClientStats();
  },
});
