
import Vue from "vue";
import Modal from "@/components/Modal/Modal.vue";
import SaveNotes from "./SaveNotes.vue";
import UserService from "@/Services/user.service";
import { backendUrl } from "@/helper/httpHelper";

export default Vue.extend({
  components: {
    Modal,
    SaveNotes,
  },
  data() {
    return {
      profileModal: false,
      sizeCard: {
        width: "600px",
      },
      profile: {},
      backendUrl: backendUrl,
    };
  },
  methods: {
    openProfileModal() {
      this.profileModal = true;
    },
    closeProfileModal() {
      this.profileModal = false;
    },
    saveNotes() {
      this.$refs.Note.api();
      this.closeProfileModal();
    },
    getUser() {
      UserService.getUser(this.$route.params.profileId)
        .then((res) => {
          this.profile = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getUser();
  },
});
