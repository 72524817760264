
import Vue from 'vue';
import MassSpamService from '@/Services/massSpam.service';
import Modal from '@/components/Modal/Modal.vue';
import { CoinPricing } from '@/types/coinPricing';
import CreateMassSpam from '@/components/Dashboard/NonUsers/MassSpams/CreateMassSpam/CreateMassSpam.vue';
import moment from "moment";

export default Vue.extend({
  components: {
    CreateMassSpam,
    Modal,
  },
  data: () => ({
    dialogDelete: false,
    dback: true,
    headers: [
      { text: 'Message', value: 'message', width: '300px' },
      { text: 'Users Messaged', value: 'receivedMessagesNumber', width: '300px' },
      { text: 'Created at ', value: 'createdAt', width: '300px' },
    ],
    massSpams: null,
    editedIndex: -1,
    editedItem: {} as CoinPricing,
    openModal: false,
    openAModal: false,
    clearFields: false,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },
  },
  watch: {
    dialog(val) {
      val || this.showModal();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getMassSpams();
    });
  },

  methods: {
    openCoinPricingModal() {
      this.openAModal = true;
    },
    closeCoinPricingModal() {
      this.openAModal = false;
    },
    openModalComponent() {
      this.openModal = true;
    },
    closeModalComp() {
      this.openModal = false;
    },
    affectDeletedRow(item) {
      if (item.deletedAt) {
        return 'deleted--background';
      }

      return 'normal--background';
    },
    getMassSpams() {
      MassSpamService.getMassSpams().then((res) => {
        this.massSpams = res.data.data;
      }).catch((err) => {
        console.log(err);
      });
    },
    showModal() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.coinPricings[this.editedIndex], this.editedItem);
      }

      this.showModal();
    },
    async createMassSpam() {
      if (this.$refs.sendMassSpam) {
        this.$refs.sendMassSpam.createMassSpam().then(() => {
          this.getMassSpams();
          this.$refs.createMassSpam.clearFields();
          this.$toasted.success('Mass spam sent successfully', {
            className: ['toasting', 'success'],
          });
        }).catch(() => {
          this.$toasted.error('Mass spam failed creating!');
        });
      }
    },
    formatDate(date){
      return moment(date).format('M/DD/YYYY');
    },
    goToMassSpamReports(row){
      this.$router.push({
        name: 'MassSpamReports',
        params: { massSpamId: row.id }
      });
    }
  },
});
