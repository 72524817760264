import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { user } from './user/index';
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const store = new Vuex.Store<RootState>({
  state: {
    helloMsg: '...',
  },
  modules: {
    user,
  },
  plugins: [vuexLocal.plugin]
});

export default store;
