
import Vue from "vue";
import Card from "../../Timeline/TimelineCards/Card.vue";
import UserService from "@/Services/user.service";
import AuthService from "@/Services/auth.service";

export default Vue.extend({
  props: {
    headerTitle: String,
    toDisplay: Boolean,
  },
  components: {
    Card,
  },

  data() {
    return {
      header: this.headerTitle,
      message: "You have no friend requests!",
      ifExists: this.toDisplay,
      user: null,
      friends: null,
    };
  },
  methods: {
    friendList() {
      AuthService.currentUser()
        .then((res) => {
          this.user = res.data;
          this.ifExists = true;
          UserService.friendList(this.user.id)
            .then((res) => {
              this.friends = res.data;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  async mounted() {
    await this.friendList();
  },
});
