
import Vue from "vue";

export default Vue.extend({
  props: {
    labelName: String,
  },
  data() {
    return {
      activePicker: null,
      date: null,
      menu: false,
      label: this.labelName,
      validDate: "",
    };
  },
  methods: {
    getDate() {
      this.$emit("passDate", this.date);
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
});
