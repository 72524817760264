
import Vue from "vue";
import CoinPricingService from "@/Services/coinPricing.service";
import { CoinPricing } from "@/types/coinPricing";

export default Vue.extend({
  components: {},
  props: {
    editCoinPricing: Object,
  },
  data() {
    return {
      coinPricing:
        this.editCoinPricing ??
        ({
          name: "",
          coinPrice: 0,
          price: 0,
          isActive: false,
          coinAmount: 0,
          hasPaypal: false,
          hasSofort: false,
          hasCall2Pay: false,
          hasMobilePay: false,
          hasPayDirekt: false,
          hasMaestroCard: false,
          hasVisa: false,
          hasMasterCard: false,
          hasKlarna: false,
          hasPaySafeCard: false,
          hasBankTransfer: false,
        } as CoinPricing),
    };
  },
  methods: {
    async createCoinPricing() {
      if (this.coinPricing.id) {
        return CoinPricingService.updateCoinPricing(this.coinPricing);
      }
      return CoinPricingService.createCoinPricing(this.coinPricing);
    },
    clearFields() {
      this.coinPricing = {
        name: "",
        coinPrice: 0,
        isActive: false,
        coinAmount: 0,
        price: 0,
        hasPaypal: false,
        hasSofort: false,
        hasCall2Pay: false,
        hasMobilePay: false,
        hasPayDirekt: false,
        hasMaestroCard: false,
        hasVisa: false,
        hasMasterCard: false,
        hasKlarna: false,
        hasPaySafeCard: false,
        hasBankTransfer: false,
      } as CoinPricing;
    },
  },
});
