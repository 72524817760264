
import Vue from 'vue';
import AccountDetails from './AccountDetails/AccountDetails.vue';
import PersonalInfo from './PersonalInformation/PersonalInfo.vue';
import OtherInfo from './OtherInformation/OtherInfo.vue';
import DeleteAccount from './DeleteAccount/DeleteAccount.vue';

export default Vue.extend({
  components: {
    AccountDetails,
    PersonalInfo,
    OtherInfo,
    DeleteAccount,
  },
});
