
import Vue from 'vue';

export default Vue.extend({
  props: {
    name: String,
    icon: String,
    isActive: Boolean,
  },
  data() {
    return {
      labelName: this.name,
      showIcon: this.icon,
    };
  },
  methods: {
    toggleClass() {
      this.$emit('activate');
    },
  },
});
