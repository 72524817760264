
import Vue from "vue";
import { backendUrl } from "@/helper/httpHelper";
import AuditLogsService from "@/Services/auditLog.service";

export default Vue.extend({
  data() {
    return {
      backEndUrl: backendUrl,
      headers: [
        { text: "User ID", value: "userId", width: "300px" },
        { text: "Description", value: "description", width: "600px" },
        { text: "Event", value: "event", width: "200px" },
        { text: "IP Address", value: "ipAddress", width: "400px" },
        { text: "Time", value: "createdAt", width: "300px" },
      ],
      users: [],
      page: 2,
      loading: true,
      numberOfPages: 0,
      totalUsers: 0,
      options: {},
    };
  },
  watch: {
    options: {
      handler() {
        this.getAuditLogs();
      },
      deep: true,
    },
  },
  methods: {
    getAuditLogs() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      let pageNumber = page;
      if (itemsPerPage == -1) {
        AuditLogsService.getAuditLogs()
          .then((res) => {
            this.users = res.data.data;
            let date;
            let time;
            // this.users.forEach((user) => {
            //   date = user.createdAt.split("T");
            //   time = date[1].split(".");
            //   user.createdAt = `${time[0]}-${date[0]}`;
            // });
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        AuditLogsService.allAuditLogs(pageNumber, itemsPerPage, "DESC")
          .then((res) => {
            this.users = res.data.data;
            this.page = res.data.meta.page;
            this.totalUsers = res.data.meta.itemCount;
            this.numberOfPages = res.data.meta.pageCount;
            this.loading = false;
            // let date;
            // let time;
            // this.users.forEach((user) => {
            //   date = user.createdAt.split("T");
            //   time = date[1].split(".");
            //   user.createdAt = `${time[0]}-${date[0]}`;
            // });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  mounted() {
    this.getAuditLogs();
  },
});
