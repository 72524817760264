
import Vue from "vue";
import ClientInfoNav from "./ClientInfoNav.vue";
import RightInfo from "./RightInfo.vue";
import AuditLogService from "@/Services/auditLog.service";

export default Vue.extend({
  components: {
    ClientInfoNav,
    RightInfo,
  },
  data() {
    return {
      page: 2,
      loading: true,
      numberOfPages: 0,
      totalUsers: 0,
      options: {},
      headers: [
        { text: "Title", value: "event", width: "1200px" },
        { text: "Date", value: "createdAt", width: "300px" },
      ],
      user: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.getAuditLogForUser();
      },
      deep: true,
    },
  },
  methods: {
    getAuditLogForUser() {
      const { page, itemsPerPage } = this.options;
      const id = this.$route.params.id;
      AuditLogService.auditLogs(id, page, itemsPerPage, "DESC")
        .then((res) => {
          this.user = res.data.data;
          let date, time;
          this.user.forEach((user) => {
            date = user.createdAt.split("T")[0];
            time = user.createdAt.split("T")[1];
            time = time.split(".")[0];
            user.createdAt = date + ":" + time;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getAuditLogForUser();
  },
});
