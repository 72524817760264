
import Vue from "vue";
import Register from "../Register/Register.vue";
export default Vue.extend({
  components: {
    Register,
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    onClickOpen() {
      this.open = true;
      this.$emit("modal", this.open);
    },
  },
});
