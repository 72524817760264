
import Vue from 'vue';
import PresentCard from './PresentCard.vue';
import PresentReceived from './PresentReceived.vue';
export default Vue.extend({
  components: {
    PresentCard,
    PresentReceived,
  },
});
