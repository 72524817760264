import { MutationTree } from 'vuex';
import { UserState } from './types';
import { User } from '@/types/User';

export const mutations: MutationTree<UserState> = {
  CREATE_PROFILE(state, payload: object) {
    state = payload;
  },
  STORE_USER(state, payload: User) {
    state.user = { ...payload };
  },
  UPDATE_COINS(state, coins) {
    state.user.coins = coins;
  },
  SAVE_GALLERY(state, gallery) {
    state.gallery = gallery;
  },
  SAVE_INDEXES(state, index) {
    state.index = index
  },
};
