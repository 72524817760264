
import Vue from "vue";
import { mapActions } from "vuex";
import { User } from "@/types/User";
import AuthService from "@/Services/auth.service";

export default Vue.extend({
  data() {
    return {
      user: {} as User,
      user1: null,
      initials: "",
    };
  },
  methods: {
    ...mapActions(["currentUser"]),
    logOut() {
      AuthService.userLogOut()
        .then((res) => {
          localStorage.clear();
          this.$router.push({ name: "Landing" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getUser() {
      const u = await this.currentUser();
      this.user = this.cUser;
      // this.initials = this.generateAvatar(this.user.displayName);
    },
    // generateAvatar(name: string) {
    //   return name.split(' ').map((str: string) => (str ? str[0].toUpperCase() : '')).join('');
    // },
  },
  computed: {
    cUser(): Object {
      return this.$store.state.user.user;
    },
  },
  mounted() {
    this.getUser();
  },
});
