
import Vue from "vue";
import MessageService from "@/Services/message.service";
import { backendUrl, socketUrl } from "@/helper/httpHelper";
import socketIO from "socket.io-client";
import { frontendUrl } from "@/helper/httpHelper";
let socket;

export default Vue.extend({
  data() {
    return {
      headers: [
        { text: "Bild", value: "bild", width: "200px" },
        { text: "Kunden", value: "user.displayName", width: "200px" },
        { text: "IN", value: "in", width: "100px" },
        {
          text: "Last Message",
          value: "lastMessage",
          width: "600px",
        },
        { text: "Out", value: "out", width: "100px" },
        { text: "Profile", value: "profile.displayName", width: "200px" },
        { text: "Profile Image", value: "profileImage", width: "200px" },
        { text: "Action", value: "actions", width: "100px" },
        { text: "Action", value: "sendToWaiting", width: "100px" },
      ],
      users: [],
      backendUrl: backendUrl,
      user: null,
      page: 2,
      loading: true,
      numberOfPages: 0,
      totalUsers: 0,
      options: {},
    };
  },
  watch: {
    options: {
      handler() {
        this.getActiveDialogs();
      },
      deep: true,
    },
  },
  methods: {
    sendToWaiting(chatId) {
      socket.emit("sendToWaiting", { chat_id: chatId });
      socket.on("chatWaiting", (res) => {
        if (res.result) {
          this.$router.push({ name: "Waiting" });
        }
      });
    },
    addBoldFont(user) {
      if (!user.lastMessage?.seenChatter && user.lastMessage?.sendType == 0) {
        return "font-bold";
      }

      return "font-normal";
    },
    getActiveDialogs() {
      const socketId = localStorage.getItem("SocketId");
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      if (itemsPerPage == -1) {
        socket.emit("chat/active", { socket_id: socketId });
        socket.on(`chat/${socketId}/active/response`, (result) => {
          if (result.chats?.data?.length > 0) {
            this.users = result.chats.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        });
      } else {
        socket.emit("chat/active", {
          socket_id: socketId,
          page: page,
          order: "DESC",
          take: itemsPerPage,
        });
        socket.on(`chat/${socketId}/active/response`, (result) => {
          if (result.chats?.data?.length > 0) {
            this.users = result.chats.data;
            this.page = result.chats.meta.page;
            this.totalUsers = result.chats.meta.itemCount;
            this.numberOfPages = result.chats.meta.pageCount;
            this.loading = false;
          } else {
            this.loading = false;
          }
        });
      }
    },
  },
  async mounted() {
    const socketId = localStorage.getItem("SocketId");
    socket = socketIO(socketUrl, {
      extraHeaders: {
        Authorization: `${localStorage.getItem("Token")}`,
      },
    });
    socket.on("chat/message/new", (res) => {
      if (res.message?.chatId) {
        const chat = this.users.find((ch) => ch.id == res.message.chatId);
        if (chat) {
          this.users = this.users.filter((ch) => ch.id != chat.id);
          chat["lastMessage"] = res.message;
          this.users.unshift(chat);
          this.users.forEach((user) => {
            if (user.lastMessage?.bold) {
              this.addBoldFont(user);
            }
          });
        }
      }
    });
    this.getActiveDialogs();
  },
});
