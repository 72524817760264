
import Vue from "vue";
import { mapActions } from "vuex";
import { createProfile } from "@/types/createProfile";
import { Gender } from "@/types/Gender";
import Birthdate from "../../Inputs/BirthDate/Birthdate.vue";

export default Vue.extend({
  props: {
    commitProfile: Function,
    saveClicked: Boolean,
  },
  components: {
    Birthdate,
  },
  data() {
    return {
      profile: {
        displayName: "",
        birthDate: "",
        gallery: null,
        mainImage: null,
        country: "",
        address: "",
        zipCode: "",
        gender: Gender,
        lookingFor: Gender,
        height: null,
        hairColor: "",
        eyeColor: "",
        figure: "",
        smoker: "",
        seeking: "",
        relationShipStatus: "",
        children: "",
        job: "",
        language: "",
        flirtText: "",
      } as unknown as createProfile,
      dialog: false,
    };
  },
  methods: {
    ...mapActions(["setProfile"]),
    createProfile() {
      // if (this.profile.displayName && this.profile.birthDate && this.profile.height
      // && this.profile.country && this.profile.gender && this.profile.lookingFor
      // && this.profile.mainImage) {
      const newProfile = this.profile as createProfile;
      const formData = new FormData();
      newProfile.gender = Gender[this.profile.gender];
      newProfile.lookingFor = Gender[this.profile.lookingFor];
      newProfile.gallery = this.$store.state.user.gallery;
      newProfile.galleryPrivates = this.$store.state.user.index;
      Object.entries(newProfile).forEach(([key, value]) => {
        if (key !== "gallery") {
          formData.append(key, value);
        }
      });

      newProfile.gallery.forEach((file) => {
        formData.append("gallery", file);
      });
      this.setProfile(formData);
      this.$toasted.success("Profile created successfully", {
        className: ["toasting", "success"],
      });
      // } else {
      //   this.$toasted.error('You must fill required fields to create a profile', {
      //     className: ['toasting', 'error'],
      //   });
      // }
    },
    getDateFromChild(value) {
      this.profile.birthDate = value;
    },
    handleInput(event) {
      this.profile.mainImage = event;
    },
    closeModalComp() {
      this.$emit("closeModal");
    },
    onClickClear() {
      this.profile = {
        displayName: "",
        birthDate: null,
        gallery: null,
        mainImage: null,
        country: "",
        address: "",
        zipCode: "",
        gender: Gender,
        lookingFor: Gender,
        height: null,
        hairColor: "",
        eyeColor: "",
        figure: "",
        smoker: "",
        seeking: "",
        relationShipStatus: "",
        children: "",
        job: "",
        language: "",
        flirtText: "",
      };
    },
  },
});
