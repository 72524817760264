
import { uploadImage } from "@/types/createProfile";
import Vue from "vue";
import AuthService from "@/Services/auth.service";
import { backendUrl } from "@/helper/httpHelper";

export default Vue.extend({
  data() {
    return {
      imageList: [],
      previewList: [],
      showPreview: false,
      privateImage: false,
      backEndUrl: backendUrl,
      reRender: true,
    };
  },
  methods: {
    previewFiles(event) {
      const input = event;
      let index = 0;
      let count = input.length;

      if (input) {
        while (count--) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.previewList.push(e.target.result);
            this.showPreview = true;
          };
          this.imageList.gallery != null
            ? this.imageList.gallery.push(input[index])
            : (this.imageList.gallery = input[index]);
          reader.readAsDataURL(input[index]);
          index++;
        }
      }
    },
    reset() {
      this.image_list = [];
      this.preview_list = [];
    },
    sendImages() {
      this.reRender = false;
      const newGallery = this.imageList as uploadImage;
      newGallery.galleryPrivates = this.privateImage;

      let formData = new FormData();

      Object.entries(newGallery).forEach(([key, value]) => {
        formData.append(key, value);
      });
      AuthService.uploadPicture(formData)
        .then((res) => {
          console.log(res);
          this.$toasted.success("Galeriebild wurde erfolgreich hochgeladen", {
            className: ["toasted", "success"],
          });
          this.reRender = true;
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.error("Etwas passiert. Versuchen Sie es nochmal!", {
            className: ["toasted", "error"],
          });
        });
    },
  },
  computed: {
    getGallery() {
      return this.$store.state.user.user.gallery;
    },
  },
});
