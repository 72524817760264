
import Vue from "vue";
import GiftService from "@/Services/gift.service";
import Modal from "@/components/Modal/Modal.vue";
import CreateGift from "@/components/Dashboard/NonUsers/Gifts/CreateGift/CreateGift.vue";
import { Gift } from "@/types/gift";
import { backendUrl } from "@/helper/httpHelper";

export default Vue.extend({
  components: {
    Modal,
    CreateGift,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Gift Image", value: "giftImage", width: "300px" },
      { text: "Gift Name", value: "name", width: "300px" },
      { text: "Gift Coin Price", value: "coinPrice", width: "300px" },
      { text: "Active", value: "isActive", width: "300px" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],
    gifts: null,
    editedIndex: -1,
    editedItem: {} as Gift,
    openModal: false,
    openAModal: false,
    cardSize: {
      height: "500px !important",
    },
    page: 2,
    loading: true,
    numberOfPages: 0,
    totalUsers: 0,
    options: {},
    backendURL: backendUrl,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.showModal();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getGifts();
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getGifts();
    });
  },
  methods: {
    openGiftModal() {
      this.openAModal = true;
    },
    closeGiftModal() {
      this.resetGiftModalFields();
      this.openAModal = false;
    },
    resetGiftModalFields() {
      this.$refs.createGift.clearFields();
      this.editedItem = null;
    },
    getGifts() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      let pageNumber = page;
      if (itemsPerPage == -1) {
        GiftService.getAllGifts()
          .then((res) => {
            this.gifts = res.data.data;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        GiftService.getGifts(pageNumber, itemsPerPage)
          .then((res) => {
            this.gifts = res.data.data;
            this.page = res.data.meta.page;
            this.totalUsers = res.data.meta.itemCount;
            this.numberOfPages = res.data.meta.pageCount;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    editItem(item) {
      this.editedIndex = this.gifts.indexOf(item);
      this.editedItem = { ...item };
      this.openAModal = true;
    },
    deleteItem(item) {
      this.editedIndex = this.gifts.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      GiftService.deleteGift(this.editedItem.id)
        .then(() => {
          this.getGifts();
        })
        .catch((err) => {
          console.log(err);
        });
      this.closeDelete();
    },
    showModal() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.gift[this.editedIndex], this.editedItem);
      }
      this.showModal();
    },
    async createGift() {
      this.$refs.createGift
        .createGift()
        .then(() => {
          this.getGifts();
          this.resetGiftModalFields();
          this.$toasted.success("Gift created successfully", {
            className: ["toasting", "success"],
          });
        })
        .catch(() => {
          this.$toasted.error("Gift failed creating!", {
            className: ["toasting", "error"],
          });
        });
    },
    updateGiftActiveStatus(giftId: number) {
      GiftService.updateGiftActiveStatus(giftId)
        .then(() => {
          this.$toasted.success("Gift active status changed! ", {
            className: ["toasting", "success"],
          });
        })
        .catch(() => {
          this.$toasted.error("Failed activating/disactivating gift!", {
            className: ["toasting", "error"],
          });
        });
    },
  },
});
