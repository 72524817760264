
import Vue from "vue";

export default Vue.extend({
  props: {
    user: Object,
  },
  data() {
    return {
      userObj: this.user,
      stringGender: "",
    };
  },
  methods: {
    // translateGender(gender: number) :string {
    //   if (gender === 0) {
    //     this.stringGender = 'Male';
    //   } else {
    //     this.stringGender = 'Female';
    //   }
    //   return this.stringGender;
    // },
  },
});
