
import Vue from "vue";
import ClientInfoNav from "./ClientInfoNav.vue";
import RightInfo from "./RightInfo.vue";
import GiftService from "@/Services/gift.service";
import { backendUrl } from "@/helper/httpHelper";

export default Vue.extend({
  components: {
    ClientInfoNav,
    RightInfo,
  },
  data() {
    return {
      page: 2,
      loading: true,
      numberOfPages: 0,
      totalUsers: 0,
      options: {},
      headers: [
        { text: "Gift Image", value: "giftImage", width: "400px" },
        { text: "Gift Title", value: "gift.name", width: "200px" },
        { text: "Gift Price", value: "gift.coinPrice", width: "200px" },
        { text: "Sent Date", value: "createdAt", width: "400px" },
        { text: "Sent To", value: "profiles.displayName", width: "200px" },
      ],
      gifts: [],
      editedIndex: -1,
      clearFields: false,
      backendUrl: backendUrl,
    };
  },
  watch: {
    options: {
      handler() {
        this.getReceivedGifts();
      },
      deep: true,
    },
  },
  methods: {
    getReceivedGifts() {
      const { page, itemsPerPage } = this.options;
      if (itemsPerPage == -1) {
        GiftService.giftsAllUserReceived(this.$route.params.id)
          .then((res) => {
            this.gifts = res.data;
            let date, time;
            this.gifts.forEach((gift) => {
              date = gift.createdAt.split("T")[0];
              time = gift.createdAt.split("T")[1];
              time = time.split(".")[0];
              gift.createdAt = date + ":" + time;
            });
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        GiftService.giftsUserReceived(
          this.$route.params.id,
          page,
          itemsPerPage,
          "DESC"
        )
          .then((res) => {
            this.gifts = res.data;
            let date, time;
            this.gifts.forEach((gift) => {
              date = gift.createdAt.split("T")[0];
              time = gift.createdAt.split("T")[1];
              time = time.split(".")[0];
              gift.createdAt = date + ":" + time;
            });
            this.loading = false;
            this.page = res.data.meta.page;
            this.totalUsers = res.data.meta.itemCount;
            this.numberOfPages = res.data.meta.pageCount;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  mounted() {
    this.getReceivedGifts();
  },
});
