
import Vue from "vue";
import ClientInfoNav from "./ClientInfoNav.vue";
import RightInfo from "./RightInfo.vue";
import PaymentService from "@/Services/payment.service";
import { backendUrl } from "@/helper/httpHelper";

export default Vue.extend({
  components: {
    ClientInfoNav,
    RightInfo,
  },
  data() {
    return {
      page: 2,
      loading: true,
      numberOfPages: 0,
      totalUsers: 0,
      options: {},
      headers: [
        { text: "Payment", value: "coinPricing.name", width: "400px" },
        { text: "Type", value: "type", width: "300px" },
        { text: "Status", value: "status", width: "300px" },
        { text: "Date Time", value: "createdAt", width: "400px" },
      ],
      user: [],
      editedIndex: -1,
      clearFields: false,
      backendUrl: backendUrl,
    };
  },
  watch: {
    options: {
      handler() {
        this.getUser();
      },
      deep: true,
    },
  },
  methods: {
    getUser() {
      const { page, itemsPerPage } = this.options;

      PaymentService.getUserPayments(
        this.$route.params.id,
        page,
        itemsPerPage,
        "DESC"
      )
        .then((res) => {
          this.user = res.data.data;
          this.page = res.data.meta.page;
          this.totalUsers = res.data.meta.itemCount;
          this.numberOfPages = res.data.meta.pageCount;
          let date, time;
          this.user.forEach((u) => {
            date = u.createdAt.split("T")[0];
            time = u.createdAt.split("T")[1];
            time = time.split(".")[0];
            u.createdAt = time + " - " + date;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getUser();
  },
});
