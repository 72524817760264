
import Vue from "vue";
import UserService from "@/Services/user.service";
import SliderForm from "./SliderForm.vue";
import CreateAgent from "./Agents/CreateAgent.vue";
import VerifyUser from "./Admins/VerifiyUser.vue";
import Modal from "../../Modal/Modal.vue";
import { backendUrl } from "@/helper/httpHelper";

export default Vue.extend({
  components: {
    Modal,
    SliderForm,
    CreateAgent,
    VerifyUser,
  },
  data: () => ({
    backendUrl: backendUrl,
    dialog: false,
    dialogDelete: false,
    page: 2,
    loading: true,
    numberOfPages: 0,
    totalUsers: 0,
    options: {},
    headers: [
      { text: "Image", value: "mainImage", width: "300px" },
      { text: "Display Name", value: "displayName", width: "300px" },
      { text: "Email", value: "email", width: "300px" },
      // { text: "isOnline", value: "isOnline", width: "300px" },
      { text: "Payments", value: "payments", width: "200px" },
      { text: "Coins", value: "coins", width: "300px" },
      { text: "Is Verified", value: "isVerified", width: "300px" },
      { text: "Register Date", value: "createdAt", width: "300px" },
      { text: "Birth Date", value: "birthDate", width: "300px" },
      { text: "Country", value: "country", width: "300px" },
      { text: "Address", value: "address", width: "300px" },
      { text: "Zipcode", value: "zipCode", width: "300px" },
      // { text: "Eye Color", value: "eyeColor", width: "300px" },
      // { text: "Figure", value: "figure", width: "300px" },
      // { text: "Smoker", value: "smoker", width: "300px" },
      // { text: "Seeking", value: "seeking", width: "300px" },
      // { text: "Children", value: "children", width: "300px" },
      // { text: "Job", value: "job", width: "300px" },
      // { text: "Language", value: "language", width: "300px" },
      // { text: "Flirt Text", value: "flirtText", width: "100px" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    coinPricings: [],
    users: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      displayName: "",
      createdAt: "",
      deletedAt: "",
      email: "",
      isProfile: false,
      coins: 0,
      birthdate: "",
      country: "",
      address: "",
      zipCode: "",
      gender: 0,
      lookingFor: 0,
      height: 0,
      hairColor: "",
      eyeColor: "",
      figure: "",
      smoker: "",
      seeking: "",
      job: "",
      language: "",
      flirtText: "",
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    openModal: false,
    openAModal: false,
    clearFields: false,
    cardSize: {
      height: "auto !important",
    },
    cardSizeEdit: {
      width: "600px",
      height: "auto !important",
    },
    openEdit: false,
    searchClients: "",
    openVerify: false,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.showModal();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getUsers();
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getUsers();
    });
  },
  methods: {
    openAgentModal() {
      this.openAModal = true;
    },
    closeCoinPricingModal() {
      this.openAModal = false;
    },
    openModalComponent() {
      this.openModal = true;
    },
    closeModalComp() {
      this.openModal = false;
    },
    closeAgentModal() {
      this.openAModal = false;
    },
    openVerifyComp() {
      this.openVerify = true;
    },
    closeVerifyComp() {
      this.openVerify = false;
    },
    affectDeletedRow(item) {
      if (item.deletedAt) {
        return "deleted--background";
      }
      return "normal--background";
    },
    getUsers() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      let pageNumber = page;
      if (itemsPerPage == -1) {
        UserService.getAllUsers(this.searchClients)
          .then((res) => {
            this.users = res.data.data;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        UserService.getUsers(this.searchClients, pageNumber, itemsPerPage)
          .then((res) => {
            this.users = res.data.data;
            this.page = res.data.meta.page;
            this.totalUsers = res.data.meta.itemCount;
            this.numberOfPages = res.data.meta.pageCount;
            this.loading = false;
            let payment;
            // let date;
            // let time;
            // this.users.forEach((user) => {
            //   if (user.payments === 0) {
            //     payment = "No";
            //     user.payments = payment;
            //   } else {
            //     payment = "Yes";
            //     user.payments = payment;
            //   }
            // });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = { ...item };
      // this.dialog = true;
      this.openVerify = true;
    },

    deleteItem(item) {
      this.editedIndex = this.coinPricings.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },

    deleteItemConfirm(item) {
      UserService.deleteUser(this.editedItem.id)
        .then((res) => {
          this.getUsers();
        })
        .catch((err) => {
          console.log(err);
        });
      this.closeDelete();
    },

    showModal() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.coinPricings[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.showModal();
    },
    submit() {
      this.$refs.sliderForm.submitProfile();
    },
    submitAgent() {
      this.$refs.myAgent.createAgent();
    },
    submitVerify() {
      this.$refs.userVerify.verifyUser();
      this.closeVerifyComp();
      this.getUsers();
    },
    createCoinPricing() {
      this.$refs.createCoinPricing.createAgent();
      this.$refs.createCoinPricing.clearFields();
    },
    // getTotalPayments(payments) {
    //   let totalPayments = 0;

    //   payments.forEach((payment) => {
    //     totalPayments += payment.coinPricing.price;
    //   });

    //   return totalPayments;
    // },
    searchUsers() {
      if (this.searchClients !== "") {
        UserService.searchClients(this.searchClients)
          .then((res) => {
            this.users = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    goToUser(id: number) {
      this.$router.push({
        name: "ClientInfo",
        params: { id },
      });
    },
  },
});
