
import Vue from "vue";
import UserService from "@/Services/user.service";
import { Role } from "@/types/Role";
import MessageService from "@/Services/message.service";
import DatePicker from "../../Inputs/BirthDate/BirthdateUnvalidated.vue";

export default Vue.extend({
  components: {
    DatePicker,
  },
  data() {
    return {
      users: [],
      headers: [
        {
          text: "User Display Name",
          value: "displayName",
          width: "700px",
        },
        { text: "Email", value: "email", width: "600px" },
        {
          text: "Waited",
          value: "totalMinutesToString",
          width: "500px",
        },
        // { text: "Country", value: "country", width: "300px" },
        // { text: "Address", value: "address", width: "300px" },
      ],
      page: 2,
      loading: true,
      numberOfPages: 0,
      totalUsers: 0,
      options: {},
      startDate: "",
      endDate: "",
    };
  },
  watch: {
    options: {
      handler() {
        this.getUsers();
      },
      deep: true,
    },
  },
  methods: {
    getStartDateFromChild(date) {
      this.startDate = date;
    },
    getEndDateFromChild(date) {
      this.endDate = date;
    },
    getMinutesFilteredByDate() {
      let userId = this.$route.params.userId;
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      let pageNumber = page;

      MessageService.getWaitingMinutes(
        userId,
        "DESC",
        pageNumber,
        itemsPerPage,
        this.startDate,
        this.endDate
      )
        .then((res) => {
          this.users = res.data.usersOnly;
          this.users.totalMinutesToString =
            res.data.totalMinutesForAllMinutesToString;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUsers() {
      const userId = this.$route.params.userId;
      const { page, itemsPerPage } = this.options;
      let pageNumber = page;
      if (itemsPerPage == -1) {
        MessageService.getWaitingMinutes(userId, "DESC", 1, 10)
          .then((res) => {
            this.users = res.data.usersOnly;
            this.loading = false;
          })
          .catch((err) => {});
      } else {
        MessageService.getWaitingMinutes(
          userId,
          "DESC",
          pageNumber,
          itemsPerPage
        )
          .then((res) => {
            this.users = res.data.usersOnly;
            this.loading = false;
          })
          .catch((err) => {});
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getUsers();
    });
  },
});
