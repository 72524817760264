
import Vue from 'vue';
import CouponService from '@/Services/coupon.service';
import Modal from '@/components/Modal/Modal.vue';
import { Coupon } from '@/types/coupon';
import CreateCoupon from '@/components/Dashboard/NonUsers/Coupons/CreateCoupon/CreateCoupon.vue';

export default Vue.extend({
  components: {
    CreateCoupon,
    Modal,
  },
  data: () => ({
    dialogDelete: false,
    dback: true,
    headers: [
      { text: 'Coupon Code', value: 'code', width: '300px' },
      { text: 'Coupon Percentage', value: 'percentage', width: '300px' },
      { text: 'Times used', value: 'coinAmount', width: '300px' },
      { text: 'Active', value: 'isActive', width: '300px' },
      {
        text: 'Actions', value: 'actions', sortable: false, align: 'center',
      },
    ],
    coupons: null,
    editedIndex: -1,
    editedItem: {} as Coupon,
    openModal: false,
    openAModal: false,
    clearFields: false,
    cardSize: {
      height: 'auto !important'
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },
  },
  watch: {
    dialog(val) {
      val || this.showModal();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getCoupons();
    });
  },

  methods: {
    openCouponModal() {
      this.openAModal = true;
    },
    closeCouponModal() {
      this.openAModal = false;
    },
    openModalComponent() {
      this.openModal = true;
    },
    closeModalComp() {
      this.openModal = false;
    },
    affectDeletedRow(item) {
      if (item.deletedAt) {
        return 'deleted--background';
      }

      return 'normal--background';
    },
    getCoupons() {
      CouponService.getCoupons().then((res) => {
        this.coupons = res.data.data;
      }).catch((err) => {
        console.log(err);
      });
    },
    editItem(item) {
      this.editedIndex = this.coupons.indexOf(item);
      this.editedItem = { ...item };
      console.log(this.editedItem);
      this.openAModal = true;
    },

    deleteItem(item) {
      this.editedIndex = this.coupons.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      // CouponService.deleteCoupon(this.editedItem.id).then(() => {
      //   this.getCoupons();
      // }).catch((err) => {
      //   console.log(err);
      // });
      this.closeDelete();
    },

    showModal() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.coupons[this.editedIndex], this.editedItem);
      }

      this.showModal();
    },
    async createCoupon() {
      if (this.$refs.createCoupon) {
        this.$refs.createCoupon.createCoinPricing().then(() => {
          this.getCoupons();
          this.$refs.createCoupon.clearFields();
          this.$toasted.success('Coupon created successfully', {
            className: ['toasting', 'success'],
          });
        }).catch(() => {
          this.$toasted.error('Gift failed creating!');
        });
      }
    },
    updateCouponActiveStatus(giftId: number) {
      CouponService.updateCouponActiveStatus(giftId).then(() => {
        this.$toasted.success('Gift active status changed!');
      }).catch(() => {
        this.$toasted.error('Failed activating/disactivating gift!');
      });
    },
  },
});
