
import Vue from "vue";
import GiftService from "@/Services/gift.service";
import { Gift } from "@/types/gift";
import { backendUrl } from "@/helper/httpHelper";

export default Vue.extend({
  props: {
    toDispaly: Boolean,
  },
  data() {
    return {
      message: "Du hast noch keine Geschenke verschenkt.",
      ifExists: this.toDispaly,
      giftsReceived: null as Gift,
      backendUrl: backendUrl,
    };
  },
  methods: {
    async getReceivedGifts() {
      GiftService.giftsAllUserReceived(this.Id)
        .then((res) => {
          this.giftsReceived = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    Id() {
      return this.$store.state.user.user.id;
    },
  },
  async mounted() {
    await this.Id;
    await this.getReceivedGifts();
  },
});
