
import Vue from "vue";
import UserService from "@/Services/user.service";
import AuthService from "@/Services/auth.service";

export default Vue.extend({
  computed: {
    currentUser(): Object {
      return this.$store.state.user;
    },
  },
  methods: {
    deleteUser() {
      UserService.deleteAccount()
        .then((res) => {
          this.$toasted.info(`Ihr Konto ist jetzt gelöscht!!`, {
            className: ["toasted", "infoToast"],
          });
          localStorage.removeItem("Token");
          localStorage.removeItem("UserId");
          localStorage.removeItem("Role");
          this.$router.push({ name: "Landing" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
});
