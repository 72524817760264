
import Vue from "vue";
import FriendList from "./FriendList/FriendList.vue";
import Navbar from "../../Navbar/NavbarComponent.vue";
import Undermenu from "../../Navbar/UnderMenu.vue";

export default Vue.extend({
  components: {
    FriendList,
    Navbar,
    Undermenu,
  },
});
