
import Vue from "vue";
import { mapActions } from "vuex";
import { createProfile } from "@/types/createProfile";

export default Vue.extend({
  data() {
    return {
      imagePreview: null,
      previewList: [],
      imageList: [],
      showPreview: false,
      closeImg: false,
      gallery: null,
      isPrivate: [],
      privateImage: [],
    };
  },
  methods: {
    ...mapActions(["setProfile"]),
    openModal() {
      this.$refs.files.click();
    },
    previewFile(event) {
      const input = this.$refs.files.files;
      let count = input.length;
      let index = 0;
      if (input) {
        while (count--) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.previewList.push(e.target.result);
            this.showPreview = true;
          };
          this.imageList.push(input[index]);
          reader.readAsDataURL(input[index]);
          index++;
        }
      }
    },
    reset() {
      this.image = null;
      this.preview = null;
      this.image_list = [];
      this.preview_list = [];
    },
    handleInputFiles(event) {
      // this.gallery = event;
    },
    closeImage(key) {
      this.$delete(this.previewList, key);
    },
    addPrivate(idx, key) {
      this.isPrivate.push(idx);
    },
    async sendImages() {
      const newGallery = this.imageList as createProfile;
      const formData = new FormData();
      console.log(newGallery);

      Object.entries(newGallery).forEach(([key, value]) => {
        formData.append(key, value);
      });
      this.$store.commit("SAVE_GALLERY", this.imageList);
      this.$store.commit("SAVE_INDEXES", this.isPrivate);
    },
  },
  mounted() {},
});
