<template>
  <div class="admin--view">
    <AdminHeader />
    <div class="admin--components">
      <Sidebar :currentUser="user" />
      <router-view></router-view>
    </div>
    <!-- <ActionButton> </ActionButton> -->
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions } from "vuex";
import { Role } from "@/types/Role";
import AdminHeader from "@/components/Dashboard/NonUsers/Admins/AdminHeader.vue";
import Sidebar from "@/components/Dashboard/NonUsers/Sidebar.vue";
// import ActionButton from "@/components/Dashboard/NonUsers/ActionButton.vue";

export default Vue.extend({
  components: {
    AdminHeader,
    Sidebar,
    // ActionButton,
  },
  data() {
    return {
      admins: [
        {
          role: Role,
          path: "",
        },
        {
          role: Role,
          path: "",
        },
      ],
      user: {},
    };
  },
  methods: {
    ...mapActions(["currentUser"]),
    async getUser() {
      await this.currentUser();
      this.user = this.computedCurrentUser;
    },
  },
  computed: {
    computedCurrentUser() {
      return this.$store.state.user.user;
    },
  },
  mounted() {
    this.getUser();
  },
});
</script>
