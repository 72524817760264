
import Vue from "vue";
import { Role } from "@/types/Role";

export default Vue.extend({
  components: {},
  props: {
    title: String,
    path: String,
    role: Number,
    currentUser: Object,
  },
  data() {
    return {
      drawer: true,
      admins: [
        ["Management", "mdi-account-multiple-outline"],
        ["Settings", "mdi-cog-outline"],
      ],
      menus: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard-variant",
          route: "Dashboard",
          roles: [Role.SuperAdmin, Role.Admin, Role.Chatter],
        },
        {
          title: "Users",
          icon: "mdi-account-group-outline",
          subMenus: [
            {
              title: "Clients",
              icon: "mdi-account",
              route: "Admin",
              roles: [Role.SuperAdmin, Role.Admin],
            },
            {
              title: "Admins",
              icon: "mdi-account",
              route: "AdminsView",
              roles: [Role.SuperAdmin, Role.Admin],
            },
            {
              title: "SuperAdmins",
              icon: "mdi-account",
              route: "SuperAdmins",
              roles: [Role.SuperAdmin, Role.Admin],
            },
            {
              title: "Advertisers",
              icon: "mdi-account",
              route: "Advertisers",
              roles: [Role.SuperAdmin, Role.Admin],
            },
            {
              title: "Chatters",
              icon: "mdi-account",
              route: "Chatters",
              roles: [Role.SuperAdmin, Role.Admin],
            },
            {
              title: "Profiles",
              icon: "mdi-account",
              route: "ProfilesView",
              roles: [Role.SuperAdmin, Role.Admin],
            },
          ],
          roles: [Role.SuperAdmin, Role.Admin],
        },
        {
          title: "Gifts",
          icon: "mdi-gift",
          route: "Gifts",
          roles: [Role.SuperAdmin, Role.Admin],
        },
        {
          title: "Coin Pricings",
          icon: "mdi-cash-multiple",
          route: "CoinPricings",
          roles: [Role.SuperAdmin, Role.Admin],
        },
        {
          title: "Mass Spams",
          icon: "mdi-forum",
          route: "MassSpams",
          roles: [Role.SuperAdmin, Role.Admin],
        },
        {
          title: "Coupons",
          icon: "mdi-ticket-percent-outline",
          route: "Coupons",
          roles: [Role.SuperAdmin, Role.Admin],
        },
        {
          title: "Waiting Room",
          icon: "mdi-timer-sand",
          route: "Waiting",
          roles: [Role.SuperAdmin, Role.Chatter],
        },
        {
          title: "Global Room",
          icon: "mdi-earth",
          route: "GlobalRoom",
          roles: [Role.SuperAdmin, Role.Chatter],
        },
        {
          title: "Active Dialogues",
          icon: "mdi-email-open-outline",
          route: "ActiveDialog",
          roles: [Role.SuperAdmin, Role.Chatter],
        },
        {
          title: "Asa",
          icon: "mdi-check-all",
          route: "Asa",
          roles: [Role.SuperAdmin, Role.Chatter],
        },

        {
          title: "Payments",
          icon: "mdi-account-cash",
          route: "Payments",
          roles: [Role.SuperAdmin],
        },
        {
          title: "Advertiser",
          icon: "mdi-account-cash",
          route: "AdvertiserReports",
          roles: [Role.Advertiser],
        },
        {
          title: "Logins",
          icon: "mdi-login",
          route: "Logins",
          roles: [Role.SuperAdmin],
        },
        {
          title: "Minutes",
          icon: "mdi-calculator-variant-outline",
          route: "Minutes",
          roles: [Role.SuperAdmin],
        },
      ],
      mini: true,
      user: this.currentUser,
      openModal: false,
    };
  },
  methods: {
    openModalComponent() {
      this.openModal = true;
    },
    closeModalComp() {
      this.openModal = false;
    },
    checkIfUserHasRoles(rolesArray: Role[]) {
      let role = this.$store.state.user.user.role;

      return rolesArray.includes(role);
    },
  },
});
