import { Http } from '@/helper/httpHelper';
import { createNote } from '@/types/createNote';

class CreateNotesService {
  createNote = (id: number, note: createNote) => Http.post(`/note/create/${id}`, note);

  getNotes = (id: number) => Http.get(`/note/user/${id}`);
}

export default new CreateNotesService();
