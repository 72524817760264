
import Vue from 'vue';
import Header from './LandingHeader/Header.vue';
import Banner from './Banner/Banner.vue';
import AboutSection from './AboutSection/AboutSectionComponent.vue';
import NewProfiles from './NewProfiles/newProfiles.vue';
import LandingFooter from './LandingFooter/LandingFooter.vue';
import ForgotPassword from './Login/ForgotPassword.vue';

export default Vue.extend({
  data() {
    return {
      closeModal: false,
      isOpen: false,
      openForgot: false,
    };
  },
  components: {
    Header,
    Banner,
    AboutSection,
    NewProfiles,
    LandingFooter,
    ForgotPassword,
  },
  methods: {
    closeModals(e) {
      // this.$refs.headerChild.closeRegisterAfterSubmit();
      this.$refs.headerChild.closeLoginModal();
    },
    getModalState(event) {
      this.isOpen = event;
    },
    openForgotPassword(e) {
      this.openForgot = e;
    },
    closeForgotPassword() {
      this.openForgot = false;
    },
  },
});
