
import Vue from "vue";
import GiftService from "@/Services/gift.service";
import { Gift } from "@/types/gift";

export default Vue.extend({
  components: {},
  props: {
    editGift: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      gift:
        this.editGift ??
        ({
          name: "",
          coinPrice: 0,
          isActive: false,
          giftImage: null,
        } as Gift),
    };
  },
  mounted() {
    console.log(this.editGift !== null);
  },
  computed: {
    giftImagePreviewUrl(): string {
      if (!this.gift.giftImage) {
        return "";
      }

      if (this.gift?.id && this.gift.giftImage.id) {
        return this.gift.giftImage.path;
      }

      return URL.createObjectURL(this.gift.giftImage);
    },
  },
  methods: {
    async createGift() {
      if (this.gift.id) {
        return GiftService.updateGift(this.gift);
      }
      return GiftService.createGift(this.gift);
    },
    clearFields() {
      this.gift = {
        name: "",
        coinPrice: 0,
        isActive: false,
        giftImage: null,
      };
    },
  },
});
