
import Vue from "vue";
import UserService from "@/Services/user.service";
import Modal from "@/components/Modal/Modal.vue";
import SliderForm from "@/components/Dashboard/NonUsers/SliderForm.vue";
import CreateAgent from "@/components/Dashboard/NonUsers/Agents/CreateAgent.vue";
import { backendUrl } from "@/helper/httpHelper";
import EditProfile from "../Admins/EditProfile.vue";

export default Vue.extend({
  components: {
    Modal,
    // CreateProfile,
    SliderForm,
    CreateAgent,
    EditProfile,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dback: true,
    headers: [
      { text: "Image", value: "mainImage", width: "300px" },
      { text: "Display Name", value: "displayName", width: "300px" },
      { text: "Birth Date", value: "birthDate", width: "300px" },
      { text: "Country", value: "country", width: "300px" },
      { text: "Address", value: "address", width: "300px" },
      { text: "Eye Color", value: "eyeColour", width: "300px" },
      { text: "Figure", value: "figure", width: "300px" },
      { text: "Smoker", value: "smoker", width: "300px" },
      { text: "Seeking", value: "seeking", width: "300px" },
      { text: "Children", value: "children", width: "300px" },
      { text: "Job", value: "job", width: "300px" },
      { text: "Language", value: "language", width: "300px" },
      { text: "Flirt Text", value: "flirtText", width: "100px" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    coinPricings: [],
    users: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      displayName: "",
      createdAt: "",
      deletedAt: "",
      email: "",
      isProfile: false,
      coins: 0,
      birthdate: "",
      country: "",
      address: "",
      zipCode: "",
      gender: 0,
      lookingFor: 0,
      height: 0,
      hairColor: "",
      eyeColor: "",
      figure: "",
      smoker: "",
      seeking: "",
      job: "",
      language: "",
      flirtText: "",
      mainImage: "",
      gallery: "",
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    page: 2,
    loading: true,
    numberOfPages: 0,
    totalUsers: 0,
    options: {},
    openModal: false,
    openAModal: false,
    clearFields: false,
    backendUrl: backendUrl,
    openEdit: false,
    cardSize: {
      height: "700px !important",
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.showModal();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getUsers();
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getUsers();
    });
  },
  methods: {
    openEditProfile() {
      this.openEdit = true;
    },
    closeEditProfile() {
      this.openEdit = false;
    },
    openAgentModal() {
      this.openAModal = true;
    },
    closeCoinPricingModal() {
      this.openAModal = false;
    },
    openModalComponent() {
      this.openModal = true;
    },
    closeModalComp() {
      this.openModal = false;
    },
    closeAgentModal() {
      this.openAModal = false;
    },
    affectDeletedRow(item) {
      if (item.deletedAt) {
        return "deleted--background";
      }
      return "normal--background";
    },
    getUsers() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      let pageNumber = page;
      if (itemsPerPage == -1) {
        UserService.getAllProfiles()
          .then((res) => {
            this.users = res.data.data;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        UserService.getProfiles(pageNumber, itemsPerPage)
          .then((res) => {
            this.users = res.data.data;
            this.page = res.data.meta.page;
            this.totalUsers = res.data.meta.itemCount;
            this.numberOfPages = res.data.meta.pageCount;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = { ...item };
      this.openEdit = true;
    },

    deleteItem(item) {
      this.editedIndex = this.coinPricings.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },

    deleteItemConfirm(item) {
      UserService.deleteUser(this.editedItem.id)
        .then((res) => {
          this.getUsers();
        })
        .catch((err) => {
          console.log(err);
        });
      this.closeDelete();
    },

    showModal() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.coinPricings[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.showModal();
    },
    submit() {
      this.$refs.sliderForm.submitProfile();
    },
    submitAgent() {
      this.$refs.myAgent.createAgent();
    },
    createCoinPricing() {
      this.$refs.createCoinPricing.createAgent();
      this.$refs.createCoinPricing.clearFields();
    },
    getTotalPayments(payments) {
      let totalPayments = 0;

      payments.forEach((payment) => {
        totalPayments += payment.coinPricing.price;
      });

      return totalPayments;
    },
  },
});
