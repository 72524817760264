
import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthService from '@/Services/auth.service';

export default Vue.extend({
  data() {
    return {
      user: {
        size: 0,
        hairColour: '',
        eyeColour: '',
        figure: '',
        smoker: '',
        seeking: '',
        relationShipStatus: '',
        children: '',
        job: '',
        langugage: '',
        flirtText: '',
      },
    };
  },

  methods: {
    editUser() {
      const editUser = this.user;
      AuthService.updateCurrentUser(editUser).then((res) => {
        this.$toasted.success('Your account is edited successfully', {
            className: ['toasting', 'success'],
          });
          location.reload();
        console.log(res);
      }).catch((err) => {
        console.log(err);
      });
    },
    getCurrentUser() {
      AuthService.currentUser().then((res) =>{
        this.user = res.data;
        console.log(this.user)
      }).catch((err) => {
        console.log(err);
      })
    },
    clear() {
      this.user.size = 0;
      this.user.hairColor = '';
      this.user.eyeColour = '';
      this.user.figure = '';
      this.user.smoker = '';
      this.user.seek = '';
      this.user.relationshipStatus = '';
      this.user.children = '';
      this.user.langugage = '';
      this.user.flirtText = '';
    },
  },
  mounted() {
    this.getCurrentUser();
  },
});
