
import Vue from "vue";
import AuthService from "@/Services/auth.service";

export default Vue.extend({
  props: {
    id: Number,
  },
  data() {
    return {
      userId: this.id,
      drawer: false,
      group: null,
      coins: null,
    };
  },
  methods: {
    logOut() {
      AuthService.userLogOut()
        .then((res) => {
          localStorage.clear();
          this.$router.push({ name: "Landing" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeMenu() {
      this.drawer = false;
    },
  },
  computed: {
    getId() {
      return localStorage.getItem("UserId");
    },
    getCurrentUserFromStore() {
      return this.$store.state.user.user;
    },
  },
});
