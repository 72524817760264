
import Vue from "vue";

export default Vue.extend({
  components: {},
  data() {
    return {
      isInAdmin: false,
    };
  },
  methods: {
    checkRoute() {
      if (this.$route.path.includes("admin")) {
        this.isInAdmin = true;
      } else {
        this.isInAdmin = false;
      }
    },
  },
  mounted() {
    this.checkRoute();
  },
});
